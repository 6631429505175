import _ from 'lodash'
import {
  startLoading,
  stopLoading,
  setInitialTable,
} from '../../../../../../../../redux/slices/table2'
import { convertFormatDateTime } from '../../../../handler/convertFormatDateTime'

export const headCellsClass = () => [
  {
    id: 'no',
    label: 'ลำดับ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'agentCode',
    label: 'Agent Code',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'fullName',
    label: 'ชื่อ-สกุล',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'trainingDateTime',
    label: 'Training date',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'courseNameENDrawer',
    label: 'หลักสูตร',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'testTypeDrawer',
    label: 'รูปแบบการทดสอบ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'ActionViewUserProfile',
    label: 'คำสั่ง',
    disablePadding: false,
    hideDelete: true,
    hideEdit: true,
    hideSortIcon: true,
    minWidth: '40px',
    isETest: true,
  },
]

export const headCellsAllLearner = () => [
  {
    id: 'no',
    label: 'ลำดับ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'agentCode',
    label: 'Agent Code',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'fullName',
    label: 'ชื่อ-สกุล',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'ActionViewUserProfile',
    label: 'คำสั่ง',
    disablePadding: false,
    hideDelete: true,
    hideEdit: true,
    hideSortIcon: true,
    minWidth: '40px',
    isETest: true,
  },
]

export const fetchData =
  (fieldName, learner, courseType) => async (dispatch) => {
    dispatch(startLoading())
    let results = _.get(learner, `${fieldName}`, [])

    let headCells = headCellsClass()
    if (courseType === 'allLearnerList') {
      headCells = headCellsAllLearner()
    }

    const prepareRows = []
    let sortedRow = []

    const AgencyAgentUser = results
      .filter((data) => {
        return (
          data.distribution === 'Agency' &&
          _.get(data, `userProfile.userType`, '') === 'AGENT'
        )
      })
      .sort((a, b) => sortByAgentCode(a, b))
    const AgencyProspectUser = results
      .filter((data) => {
        return (
          data.distribution === 'Agency' &&
          _.get(data, `userProfile.userType`, '') === 'PROSPECT'
        )
      })
      .sort((a, b) => sortByAgentCode(a, b))
    const BancaUser = results
      .filter((data) => {
        return data.distribution === 'Banca'
      })
      .sort((a, b) => sortByAgentCode(a, b))
    const KTBUser = results
      .filter((data) => {
        return data.distribution === 'KTB'
      })
      .sort((a, b) => sortByAgentCode(a, b))
    const OtherUser = results
      .filter((data) => {
        return !['Agency', 'Banca', 'KTB'].includes(data.distribution)
      })
      .sort((a, b) => {
        const aSort = a.agentCode
        const bSort = b.agentCode
        if (aSort < bSort) {
          return -1
        }
        if (aSort > bSort) {
          return 1
        }
        return 0
      })
    prepareRows.push(...AgencyAgentUser)
    prepareRows.push(...AgencyProspectUser)
    prepareRows.push(...BancaUser)
    prepareRows.push(...KTBUser)
    prepareRows.push(...OtherUser)

    for (const [index, value] of prepareRows.entries()) {
      const isNotEmpty = (data) => !_.isEmpty(data)
      const startClass = convertFormatDateTime({
        value: value.reportEtesting?.startClassDateTime,
        type: 'date',
      })
      const endClass = convertFormatDateTime({
        value: value.reportEtesting?.endClassDateTime,
        type: 'date',
      })
      let tableDate = '-'
      if (isNotEmpty(startClass) && isNotEmpty(endClass)) {
        tableDate = `${startClass} - ${endClass}`
      } else if (isNotEmpty(startClass) && _.isEmpty(endClass)) {
        tableDate = `${startClass}`
      }

      sortedRow.push({
        ...value,
        no: index + 1,
        fullName:
          (_.get(value, 'firstNameTH') ?? '-') +
          ' ' +
          (_.get(value, 'lastNameTH', '') ?? ''),
        trainingDateTime: tableDate,
        testTypeDrawer: setTesterListTestType(
          _.get(value, 'reportEtesting.testType', '-'),
          _.get(value, 'reportEtesting.typeOfETesting', '-'),
        ),
        courseNameENDrawer: _.get(value, 'reportEtesting.courseNameEN') ?? '-',
      })
    }

    dispatch(
      setInitialTable({
        rows: sortedRow,
        isCheckBox: false,
        allCount: results.length,
        headCells: headCells,
        searchKey: 'name',
        hideFilter: true,
        rowsPerPage: 10,
      }),
    )

    dispatch(stopLoading())
  }

export const sortByAgentCode = (a, b) => {
  if (a.agentCode < b.agentCode) {
    return -1
  }
  if (a.agentCode > b.agentCode) {
    return 1
  }
  return 0
}

function setTesterListTestType(type, typeOfETesting) {
  switch (typeOfETesting) {
    case 'STANDALONE':
      return 'Other'
    default:
      return type
  }
}
