import { Box, Typography } from '@mui/material'
import { FilterBox } from '../FilterBox'
import { shallowEqual, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import _ from 'lodash'
import RemarkTooltip from '../../../../../../../modules/EConfiguration/components/RemarkTooltip'

const LeftInfo = ({ isPrinting = false }) => {
  const { latestUpdated, isManageClass } = useSelector(
    (state) => ({
      latestUpdated: state.crud.eEvaluationDashboard?.latestUpdated,
      isManageClass: state.crud.eEvaluationDashboard?.isManageClass,
    }),
    shallowEqual,
  )
  const user = JSON.parse(localStorage.getItem('user'))
  const permissionList = _.get(user, 'permission', [])
  const hasPermission = _.find(permissionList, (item) => {
    return (
      ['ALL'].includes(item.owner) &&
      item.permission === 'VIEW' &&
      item.module === 'E_EVALUATION_SETTING'
    )
  })
  return (
    <Box>
      {(!isManageClass || (isManageClass && hasPermission)) && (
        <Box
          display="flex"
          gap={0}
          className={`${isPrinting ? 'hide-pdf' : ''}`}
        >
          <Typography variant="h6">ภาพรวม</Typography>
          <RemarkTooltip IconSize={'16px'} wrapperSx={{ mt: '7px' }} />
        </Box>
      )}
      <Box mt={2}>
        {!isManageClass && <FilterBox />}
        <Typography variant="body2">
          ข้อมูลเมื่อวันที่{' '}
          {latestUpdated ?? dayjs().format('DD/MM/YYYY 04:00')}
        </Typography>
      </Box>
    </Box>
  )
}

export default LeftInfo
