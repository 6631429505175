import _ from 'lodash'
import axios from 'axios'
import { createListenerMiddleware } from '@reduxjs/toolkit'
import { setEExamType, setExamList, setInitialExamList } from '.'
import { examinationListFilter } from '../../../../utils/apiPath'
import {
  headCells,
  headCellsCompany,
  headCellsOther,
  SCHEDULE_LIST_STATUS_COLOR,
  SCHEDULE_STATUS,
  SCHEDULE_STATUS_TEXT_TH,
} from '../../../../constants/eExamination'
import {
  startLoading,
  stopLoading,
  setInitialTable,
  setSearchText,
  setSearchDate,
  setFilterProp,
  resetTablePage,
  setSelected,
  setRows,
  setChangeRowsPerPage,
} from '../../table'
import {
  onDownload,
  handleQuickSearch,
  handleQuickDateSearch,
  handleDeleteModule,
  clearDateRangeSearch,
} from '../../../../modules/EExamination/List/event'
import { E_EXAM_TYPE } from '../../../../constants/eExamination'
import { convertFormatDateTime } from '../../../../utils/lib'
import { setDefaultSort, setOrder } from '../../table'

export const listenerMiddlewareExamination = createListenerMiddleware()

listenerMiddlewareExamination.startListening({
  actionCreator: setEExamType,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(setInitialExamList(true))
    listenerApi.dispatch(startLoading())
    listenerApi.dispatch(setSearchText(''))
    listenerApi.dispatch(setSearchDate(''))
    listenerApi.dispatch(clearDateRangeSearch())
    listenerApi.dispatch(setDefaultSort({ state: 0, id: '', active: false }))
    listenerApi.dispatch(setOrder({ order: 'desc', sort: 'updatedAt' }))
    listenerApi.dispatch(setFilterProp(null))
    listenerApi.dispatch(resetTablePage())
    listenerApi.dispatch(setSelected([]))
    listenerApi.dispatch(
      setChangeRowsPerPage({
        defaultRowsPerPage: 20,
        limit: 20,
        rowsPerPage: { label: '20', value: 20 },
      }),
    )
    const { eExamType, examinationPermissions } =
      listenerApi.getState().eExamList
    const { table: tableProps } = listenerApi.getState().table
    const body = {
      search: '', //ค้นหา
      region: [], //ค้นหา ภาคโดยการส่ง UUID มา
      provinceName: '', //ค้นหาชื่อ สนามสอบ (สำหรับ สมาคม, อื่นๆ)
      stationName: '', //ค้นหาชื่อ สนามสอบ (สำหรับบริษัท)
      otherName: '', //ค้นหาชื่อ สนามสอบ (สำหรับ อื่นๆ)
      startDate: '', //วันที่เริ่มสอบ
      endDate: '', //วันที่สิ้นสุดการสอบ
      startDateRegister: '', //วันที่เริ่มเปิดรับสมัคร
      endDateRegister: '', //วันที่สิ้นสุดเปิดรับสมัคร
      startOfDateRegister: '', //วันที่เริ่มปิดรับสมัคร
      endOfDateRegister: '', //วันที่สิ้นสุดปิดรับสมัคร
      numberOfPeople: -1, //จำนวนผู้สมัครสอบ
      createdBy: '', //สร้างโดย
      updatedBy: '', //แก้ไขล่าสุดโดย
      status: [], // สถานะ OPEN_REGISTER,IN_PROGRESS,CANCEL,CLOSED_REGISTER
      limit: _.get(tableProps, 'limit', '20'),
      order: 'DESC',
      page: 1,
      sort: 'updatedAt', // stationName | stationNameEN | locationCode | updatedAt
      schedule: eExamType, //ASSOCIATION, COMPANY, OTHER
    }
    let response = await axios
      .post(examinationListFilter, body)
      .then((res) => res.data)
    let results = _.get(response, 'result', [])
    const totalCount = _.get(response, 'totalCount', 0)
    results = _.map(results, (item) => {
      return {
        ...item,
        examStatus: SCHEDULE_STATUS_TEXT_TH[_.get(item, 'status')],
        textColor: SCHEDULE_LIST_STATUS_COLOR[_.get(item, 'status')],
        status: [SCHEDULE_STATUS.CANCEL, SCHEDULE_STATUS.COMPLETED].includes(
          _.get(item, 'status'),
        )
          ? 'INACTIVE'
          : 'ACTIVE',
        proviceName: _.get(item, 'other.association.provinceName'),
        regionName:
          eExamType === E_EXAM_TYPE.ASSOCIATION
            ? _.get(item, 'association.region.regionName')
            : _.get(item, 'other.association.region.regionName'),
        examStation:
          eExamType === E_EXAM_TYPE.COMPANY
            ? _.get(item, 'station.academyStationName')
            : eExamType === E_EXAM_TYPE.ASSOCIATION
            ? _.get(item, 'association.provinceName')
            : _.get(item, 'other.otherName'),
        examDate: `${convertFormatDateTime({
          value: _.get(item, 'date'),
          type: 'date',
        })} ${_.get(item, 'time.startTime')} - ${_.get(item, 'time.endTime')}`,
        examStartRegisterDate: `${convertFormatDateTime({
          value: _.get(item, 'startDateRegister'),
          type: 'date',
        })} ${_.get(item, 'startTimeRegister')} `,
        examEndRegisterDate: `${convertFormatDateTime({
          value: _.get(item, 'endDateRegister'),
          type: 'date',
        })} ${_.get(item, 'endTimeRegister')}`,
        numberOfPeople: `${item.countStudent ?? 0}/${item.numberOfPeople ?? 0}`,
      }
    })
    listenerApi.dispatch(setExamList(results))

    const headCellsData =
      eExamType === E_EXAM_TYPE.COMPANY
        ? headCellsCompany
        : eExamType === E_EXAM_TYPE.OTHER
        ? headCellsOther
        : headCells
    const actionExamIndex = headCellsData.findIndex(
      (x) => x.id === 'actionExam',
    )
    _.assign(headCellsData[actionExamIndex], examinationPermissions)
    let handleSearch = (text) =>
      listenerApi.dispatch(
        handleQuickSearch(
          listenerApi.getState().table,
          text,
          '',
          '',
          null,
          examinationPermissions,
        ),
      )
    if (examinationPermissions?.hideView) handleSearch = ''
    let handleDateSearch = (text) =>
      listenerApi.dispatch(
        handleQuickDateSearch(
          listenerApi.getState().table,
          text,
          '',
          null,
          examinationPermissions,
        ),
      )
    if (examinationPermissions?.hideView) handleDateSearch = ''
    let handleOnDownload = (selected, sort, order) =>
      listenerApi.dispatch(onDownload(selected, sort, order))
    if (examinationPermissions?.hideView) handleOnDownload = ''

    listenerApi.dispatch(
      setInitialTable({
        rows: results,
        allCount: totalCount,
        headCells: headCellsData,
        placeholder: 'ค้นหาสนามสอบ',
        searchKey: 'name',
        handleSearch: handleSearch,
        handleDateSearch: handleDateSearch,
        onDelete: (row) =>
          listenerApi.dispatch(
            handleDeleteModule(
              row,
              listenerApi.getState().table,
              examinationPermissions,
            ),
          ),
        onDownload: handleOnDownload,
      }),
    )
    let resultArr = []
    let resultLength = results.length
    let count = 0
    for (const result of results) {
      resultArr = [...resultArr, result]
      count++
      if (resultArr.length === resultLength) {
        listenerApi.dispatch(setRows(resultArr))
      } else if (count >= 30) {
        count = 0
        listenerApi.dispatch(setRows(resultArr))
      }
    }
    listenerApi.dispatch(setInitialExamList(false))
    listenerApi.dispatch(stopLoading())
  },
})
