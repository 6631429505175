export const initialState = {
  search: {
    placeholder: '',
    searchText: '',
    searchDate: '',
    searchDateRange: {
      startDate: '',
      endDate: '',
      displayDate: false,
    },
  },
  table: {
    isLoading: false,
    isCheckBox: false,
    rows: [],
    headCells: [],
    allCount: 0,
    page: -1,
    tablePage: 0,
    selected: [],
    numSelected: 0,
    order: 'desc',
    sort: 'updatedAt',
    defaultSortId: 'updatedAt',
    defaultSort: { state: 0, id: '', active: false },
    limit: '100',
    rowsPerPage: { label: '100', value: 100 },
    isShowPagination: true,
  },
  filter: {
    isFilterDrawer: false,
    status: [],
    warehouseType: [],
    filterProp: null,
    filterTotal: 0,
    quickFilter: [],
    other: null,
  },
  filterState: null,
  filterStateMemo: null,
  handleSearch: null,
  handleDateSearch: null,
  onDownload: null,
  onUpload: null,
  onDelete: null,
  onQuickFilter: null,
  onChangeStatus: null,
  onEligibleExam: null,
  customStyle: {},
  customContainerStyle: {},
  customToolbar: null,
  customToolbarSx: null,
}

export const initialStateBasic = {
  isLoading: false,
  rows: [],
  headCells: [],
  allCount: 0,
  page: -1,
  tablePage: 0,
  order: 'desc',
  sort: 'updatedAt',
  defaultSortId: 'updatedAt',
  defaultSort: { state: 0, id: '', active: false },
  limit: '100',
  rowsPerPage: { label: '100', value: 100 },
  isShowPagination: true,
}
