export const initialState = {
  isFirst: false,
  isDraft: true,
  isDisable: false,
  isCancel: false,
  isLoading: false,
  isSubmit: false,
  isLoadingRoomAvailable: false,
  adminStaffOption: [],
  meetingRoomOption: [],
  externalSpeakerOption: [],
  responsibilityOption: [],
  error: null,
  data: {
    uuid: '',
    publicClass: false,
    sendMailUpdate: false,
    startRegisterDateTime: '',
    endRegisterDateTime: '',
    assignNee: '',
    assignNeeObj: null,
    tel: '', // admin's tel
    contact: '',
    detail: '',
    monthlyPlan: {},
    roomManagement: {},
    roomManagementMeetingRoomDefault: [],
    meetingRoomByDate: [],
    selectedAssignNee: {},
  },
}

export const initSpeaker = {
  staff: { uuid: '' },
  responsibility: { uuid: '' },
  startTime: '',
  endTime: '',
}

export const initOnlineRoom = {
  link: '',
  contact: '',
  learnerNumber: '', // number
  adminStaffPlan: [], // { uuid: 'abc' }
}
