import _ from 'lodash'
import {
  startLoading,
  stopLoading,
  setInitialTable,
  setSelected,
  setSearchText,
  resetTablePage,
  setStatusFilter,
  resetPage,
} from '../../../../../redux/slices/table'
import { path } from '../../../../../constants/path'
import { getELearningDashboardAllCourse } from '../../../../../services/eLearning/dashboard'
import { DEFUALT_MODULE_STATUS } from '../../model'
import { downloadFile } from '../../../../../services/util'
import { eLearningDashboardAllCourseDownload } from '../../../../../utils/apiPath'

export const headCellsCourse = [
  {
    id: 'courseCode',
    label: 'รหัสหลักสูตร',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'name',
    label: 'ชื่อหลักสูตร',
    disablePadding: false,
    hideSortIcon: true,
    width: '400px',
  },
  {
    id: 'courseType',
    label: 'ประเภทหลักสูตร',
    disablePadding: false,
    hideSortIcon: true,
    chipContent: true,
  },
  {
    id: 'learnerNotStartCount',
    label: 'ผู้เรียน Not Start',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'learnerInprogressCount',
    label: 'ผู้เรียน Inprogress',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'learnerCompleteCount',
    label: 'ผู้เรียน Complete',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'action',
    label: '',
    disablePadding: false,
    hideDelete: true,
    hideEdit: true,
    hideSortIcon: true,
    viewPath: `${path.eLearningCourse}/view`,
    width: '40px',
  },
]

export const headCellsEnrollCourse = [
  {
    id: 'courseCode',
    label: 'รหัสหลักสูตร',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'name',
    label: 'ชื่อหลักสูตร',
    disablePadding: false,
    hideSortIcon: true,
    width: '400px',
  },
  {
    id: 'courseType',
    label: 'ประเภทหลักสูตร',
    disablePadding: false,
    hideSortIcon: true,
    chipContent: true,
  },
  {
    id: 'learnerCount',
    label: 'จำนวนผู้เรียน Enroll',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'ActionViewEnrollList',
    disablePadding: false,
    hideSortIcon: true,
  },
]

export const fetchData =
  ({
    table,
    drawerType,
    courseType,
    keyword,
    startDate,
    endDate,
    type,
    status,
  }) =>
  async (dispatch) => {
    const realPage = table.page <= 0 ? 1 : +table.page
    dispatch(startLoading())
    const headCells =
      drawerType === 'course' ? headCellsCourse : headCellsEnrollCourse

    try {
      const body = {
        limit: _.get(table, 'limit', '100'),
        page: realPage,
        name: keyword || '',
        courseType: courseType === 'all' ? '' : courseType,
        periodStart: startDate,
        periodEnd: endDate,
        type,
        status,
      }
      const result = await dispatch(getELearningDashboardAllCourse(body))
      const payload = _.get(result, 'payload', undefined)
      if (payload) {
        dispatch(
          setInitialTable({
            rows: prepareRow(payload.results),
            allCount: _.get(result, 'payload.count', 0),
            headCells: headCells,
            placeholder: 'ค้นหา',
            searchKey: 'name',
            hideFilter: true,
            handleSearch: (text) =>
              dispatch(
                handleQuickSearch(
                  table,
                  drawerType,
                  courseType,
                  text,
                  startDate,
                  endDate,
                  type,
                  status,
                ),
              ),
            onDownload: (selected) =>
              dispatch(onDownload(selected, drawerType, courseType)),
          }),
        )
        dispatch(setSelected([]))
      }
    } catch (e) {
      dispatch(setInitialTable({ rows: [], allCount: 0, headCells: headCells }))
      return e.message?.includes('method')
    }
    dispatch(stopLoading())
  }

export const handleQuickSearch =
  (table, drawerType, courseType, keyword, startDate, endDate, type, status) =>
  (dispatch) => {
    dispatch(setSearchText(keyword))
    dispatch(resetTablePage())
    if (table.page === 1) {
      dispatch(
        fetchData({
          table,
          drawerType,
          courseType,
          keyword: keyword,
          startDate,
          endDate,
          type,
          status,
        }),
      )
    } else {
      dispatch(resetPage())
      dispatch(setStatusFilter(DEFUALT_MODULE_STATUS))
    }
  }

export const onDownload =
  (selected, drawerType, courseType) => async (dispatch) => {
    dispatch(startLoading())
    const body = {
      type: drawerType ? 'COURSE' : 'LEARNER',
      courseType: courseType === 'all' ? '' : courseType,
      list: selected,
    }

    await dispatch(
      downloadFile({
        url: eLearningDashboardAllCourseDownload,
        body: body,
        fileName: `รายการหลักสูตร.xlsx`,
      }),
    )
    dispatch(stopLoading())
  }

const prepareRow = (data) => {
  return data.map((ele) => {
    return {
      ...ele,
      courseType: _.uniq(
        ele.courseType.map((type) => {
          return ['Agency', 'Banc', 'KTB'].includes(type.label)
            ? type.label
            : 'Other'
        }),
      ),
    }
  })
}
