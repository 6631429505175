import {
  Chart,
  BarController,
  DoughnutController,
  PieController,
  RadarController,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js'

Chart.register(
  BarController,
  DoughnutController,
  PieController,
  RadarController, // Chart types
  CategoryScale,
  LinearScale,
  RadialLinearScale, // Scales
  PointElement,
  LineElement,
  BarElement,
  ArcElement, // Elements
  Tooltip,
  Legend, // Plugins
)
