import React from 'react'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import * as Styles from './Styled'
import { handleChangeTab } from './event'

const Index = () => {
  const dispatch = useDispatch()
  const defaultTap = useSelector(
    (state) => state.eEvaluationForm.defaultTap,
    shallowEqual,
  )
  return (
    <Styles.StyledTab
      data-testid="tap-menu-form"
      value={defaultTap}
      textColor="primary"
      indicatorColor="primary"
      aria-label="primary tabs"
      onChange={(_, value) => dispatch(handleChangeTab(value))}
    >
      <Tab
        value="assessment"
        label={
          <Typography variant="body1b" color="primary">
            แบบประเมิน
          </Typography>
        }
      />
      <Tab
        value="setting"
        label={
          <Typography variant="body1b" color="primary">
            การตั้งค่า
          </Typography>
        }
      />
    </Styles.StyledTab>
  )
}
export default Index
