import Box from '@mui/material/Box'
import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import { Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import Breadcrumbs from '../../../../components/Breadcrumbs'
import { breadcrumbExaminationForm } from '../Module/Lib/constanst'
import {
  examinationAssociation,
  examinationRegionList,
} from '../../../../utils/apiPath'
import { onView } from '../Module/Lib/constanst'
import Form from '../Module/Form'
import { validationSchema } from '../Module/Lib/constanst'
import { DialogContext } from '../../../../context/dialog-context'
import { callAPI } from '../../../../utils/lib'
import {
  closeDialog,
  openDialog,
} from '../../../../redux/slices/dialog'
import _ from 'lodash'
import { useDispatch } from 'react-redux'

const Index = ({isEditForm}) => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  const history = useHistory()
  const [isPageLoading, setIsPageLoading] = useState(false)
  const { context, setContext: setDialog } = useContext(DialogContext)
  const dialog = context.dialog
  const [isLoading, setIsLoading] = useState(false)
  const [getRegion, setGetRegion] = useState([])
  const [initialValues, setInitialValues] = useState({
    region: '',
    provinceName: '',
    locationCode: '',
    description: '',
    annotation: '',
    status: true,
  })
  const titleText = isEditForm ? 'แก้ไขสนามสอบ' : 'สร้างสนามสอบ'
  let breadCrumbText = isEditForm ? 'แก้ไขเพิ่มสนามสอบ(สมาคม)' : 'เพิ่มสนามสอบ(สมาคม)'

  useEffect(() => {
    fetchRegion({ setGetRegion })
    if (isEditForm) {
      setIsPageLoading(true)
      fetchExaminationById({
        uuid,
        setInitialValues,
        formik,
        setDialog,
        setIsPageLoading,
      })
    }
  }, [isEditForm])

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      values.status = initialValues.status
      setDialog({
        ...dialog,
        title: 'ยืนยันการบันทึกข้อมูล',
        content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
        variant: 'save',
        open: true,
        isLoading: false,
        onConfirmClick: () =>
          submitExaminationField({
            uuid,
            values,
            isEditForm,
            setDialog,
            setIsLoading,
            history,
            formik,
            dispatch,
          }),
        onCancelClick: () =>
          setDialog({
            variant: 'save',
            content: '',
            open: false,
          }),
      })
    },
  })

  return (
    <>
      <Box sx={{ pb: 2 }}>
        <Box
          sx={{
            opacity: isPageLoading ? 0.4 : 'unset',
            pointerEvents: isPageLoading ? 'none' : 'unset',
          }}
        >
          <Box
            sx={{
              mx: 3,
              justifyContent: 'space-between',
              display: 'flex',
            }}
          >
            <Typography variant="h4">
              {titleText}
            </Typography>
          </Box>
          <Breadcrumbs
            sx={{ mx: 3, mb: 3 }}
            menuList={breadcrumbExaminationForm({titleText: breadCrumbText})}
          />
          <Form
            isEditForm={isEditForm}
            dialog={dialog}
            setDialog={setDialog}
            handleClose={() => onCancelClickDialog(setDialog)}
            formik={formik}
            isLoading={isLoading}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            getRegion={getRegion}
          />
        </Box>
        <CircularProgress
          disableShrink
          sx={{
            position: 'absolute',
            display: isPageLoading ? 'unset' : 'none',
            top: '50%',
            left: '50%',
            color: 'primary.main',
          }}
        />
      </Box>
    </>
  )
}

export const onCancelClickDialog = (setDialog) => {
  setDialog({ open: false })
}

export const submitExaminationField = async ({
  uuid,
  values,
  isEditForm,
  setDialog,
  setIsLoading,
  history,
  formik,
  dispatch,
}) => {
  const methodType = isEditForm ? 'put' : 'post'
  let body = {
    region: values.region,
    provinceName: values.provinceName,
    locationCode: values.locationCode,
    description: values?.description ?? '',
    annotation: values?.annotation ?? '',
    status: values.status ? 'ACTIVE' : 'INACTIVE',
  }

  if (isEditForm)
    body = {
      ...body,
      id: values.id,
      uuid: uuid,
    }
  setDialog({ open: true, isLoading: true })
  callAPI({
    method: methodType,
    url: examinationAssociation,
    body: body,
    onSuccess: (res) => {
      const uuidLink = isEditForm ? uuid : res
      setDialog({ variant: 'success', content: '', open: false, isLoading: false })
      dispatch(
        openDialog({
          type: 'success',
          title: 'สำเร็จ',
          message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          handleConfirm: () => {
            dispatch(closeDialog())
            setDialog({ variant: 'save', content: '', open: false })
            onView({ uuid: uuidLink }, history)
          },
        })
      )
    },
    onError: (er) => {
      if(er.response.data?.property === 'IsMasterDataDuplicateProvinceName'){
        formik.setErrors({ ...formik.errors, provinceName:'ชื่อนี้ถูกใช้งานแล้ว' })
      }else if(er.response.data?.message[0]?.property && er.response.data.message[0].property === "provinceName"){
        formik.setErrors(
          { ...formik.errors, provinceName:'ชื่อจังหวัดนี้ถูกใช้งานแล้ว', locationCode: 'รหัสนี้ถูกใช้งานแล้ว' }
        )
      }
      setDialog({ open: false, isLoading: false })
    },
    onFinally: () => {
      setIsLoading(false)
      setDialog({ isLoading: false })
    },
  })
}

export const fetchExaminationById = async ({
  uuid,
  setInitialValues,
  formik,
  setDialog,
  setIsPageLoading,
}) => {
  callAPI({
    method: 'get',
    url: examinationAssociation + `/${uuid}`,
    onSuccess: (res) => {
      const { 
        annotation, description, id, locationCode, provinceName, region, 
        updatedBy, updatedAt, createdBy, createdAt
      } = res
      const status = _.get(res, 'status', true)

      setInitialValues((prevInitialValues)=>{
        return {
          ...prevInitialValues,
          id: id,
          region: region?.uuid,
          provinceName: provinceName,
          locationCode: locationCode,
          description: description,
          annotation: annotation,
          status: status === 'ACTIVE' ? true : false,
          createdBy: createdBy,
          createdAt: createdAt,
          updatedBy: updatedBy,
          updatedAt: updatedAt,
        }
      })
      formik.setFieldValue('id', id)
      formik.setFieldValue('region', region?.uuid)
      formik.setFieldValue('provinceName', provinceName)
      formik.setFieldValue('locationCode', locationCode)
      formik.setFieldValue('description', description)
      formik.setFieldValue('annotation', annotation)
    },
    onError: (er) => {
      formik.setErrors({ provinceName: er.response.data.message[0] })
      setDialog({
        open: true,
        variant: 'fail',
        content: er.response.data.message[0],
        onConfirmClick: () =>
          setDialog({
            variant: 'fail',
            content: er.response.data.message[0],
            open: false,
          }),
      })
    },
    onFinally: () => {
      setIsPageLoading(false)
    },
  })
}

export const fetchRegion = async ({ setGetRegion }) => {
  callAPI({
    method: 'post',
    url: examinationRegionList,
    body: {
      regionName: '',
      createdBy: '',
      updatedBy: '',
      limit: -1,
      order: 'ASC',
      page: 1,
      sort: 'id',
      status: ['ACTIVE'],
    },
    onSuccess: (data) => {
      setGetRegion(_.get(data, 'result', []))
    },
  })
}


export default Index
