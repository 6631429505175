import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Event from '@mui/icons-material/Event'
import DateRangePicker from './DateRangePicker'
import { formatDateDisplay, getDisabledDates } from './events'
import { StyledDateInput, StyledDateRangeContainer } from './Styled'
import { Close } from '@mui/icons-material'

const DateRangeInput = (props) => {
  const {
    labelText,
    labelVariant = 'body2',
    disabled,
    required,
    dateState,
    disableDates,
    placeholder = 'ค้นหา',
    maxDate,
    minDate,
    onChange,
    textError,
    showTextError = true,
    isClearable = false,
    handleClear = () => {},
    clearButtonId = null,
  } = props
  const ref = useRef(null)
  const [isOpen, setOpen] = useState(false)
  const [state, setState] = useState([dateState])
  const [disableDateArr, setDisableDate] = useState([])
  const isError = showTextError && !_.isEmpty(textError)

  useEffect(() => {
    const allDisabledDates = getDisabledDates(disableDates)
    setDisableDate(allDisabledDates)
  }, [disableDates])

  useEffect(() => {
    document.addEventListener('click', handleClickAway, true)
  }, [])

  const handleClickAway = (e) => {
    if (ref.current && !ref.current.contains(e.target)) setOpen(false)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex' }}>
        <Typography
          variant={labelVariant}
          color={disabled ? 'text.gray' : 'text.secondary'}
        >
          {labelText}
        </Typography>
        {required && (
          <Typography
            sx={{ lineHeight: 1, ml: 0.5 }}
            color={disabled ? 'text.gray' : 'error'}
          >
            *
          </Typography>
        )}
      </Box>
      <StyledDateRangeContainer>
        <StyledDateInput
          data-testid="date-input"
          disabled={disabled}
          isError={isError}
          onClick={() => !disabled && setOpen((e) => !e)}
        >
          {state[0].display ? (
            <Typography color={disabled ? 'text.disabled' : 'text.primary'}>
              {formatDateDisplay(state[0].startDate)} -{' '}
              {formatDateDisplay(state[0].endDate)}
            </Typography>
          ) : (
            <Typography color="text.lightGray">{placeholder}</Typography>
          )}
          {isClearable && state[0].display && (
            <IconButton
              sx={{ mr: 3 }}
              id={clearButtonId}
              onClick={() => {
                handleClear()
                setState([
                  {
                    ...state[0],
                    startDate: new Date(),
                    endDate: new Date(),
                    display: false,
                  },
                ])
              }}
            >
              <Close color="action.active" />
            </IconButton>
          )}

          <IconButton color="primary" disabled={disabled}>
            <Event />
          </IconButton>
        </StyledDateInput>
        <div ref={ref}>
          {isOpen && (
            <DateRangePicker
              maxDate={maxDate}
              minDate={minDate}
              selectedDates={state}
              disableDates={disableDateArr}
              onChange={(item) => {
                const newDateState = { ...item.selection, display: true }
                setState([newDateState])
                onChange(newDateState)
              }}
            />
          )}
        </div>
      </StyledDateRangeContainer>
      {isError && (
        <Typography variant="body2" color="error">
          {textError}
        </Typography>
      )}
    </Box>
  )
}

export default DateRangeInput
