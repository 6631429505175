import { crudConfig as initialCrudConfig } from './crudConfig'
import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import CFilterDrawer from '../FilterDrawer'
import { filterItems } from './model/filterItems'
import { defaultFilter } from './model/defaultFilter'

import { initialConfig } from '../../../../../../components/CRUD/handler/initialConfig'
import _ from 'lodash'
import { mutateFilterPropToBody } from './mutation/filterPropToBody'
import { mutateFilterSateToProp } from './mutation/filterStateToProp'
import dayjs from 'dayjs'
import { FilterBox } from '../filterBox'
import {
  // StyledCard,
  StyledContainer,
} from '../../../../../Styled'
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import {
  ExpandMore,
  FilterListOutlined,
  InboxTwoTone,
  KeyboardArrowRightRounded,
} from '@mui/icons-material'
import {
  resetTable,
  setToggleFilterDrawer,
} from '../../../../../../redux/slices/table'

import {
  CustomAccordion,
  DataRow,
  Row,
  StyledContentBox,
  StyledToolbar,
} from '../Styled'
import InfoCard from './components/InfoCard'
import BarChart from './components/BarChart'
import { CContainer } from '../../../../../../components/CRUD/components/Container'
import { setDashboardTable } from '../../handler/setDashboardTable'
import { useDispatch } from 'react-redux'
import ClassDrawer from './components/ClassDrawer'
import { openCourseDrawer } from '../../../../../../redux/slices/ETestingDashboard'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { filterEtestingDashboardOverview } from '../../../../../../utils/apiPath'
import { handleFiltering } from '../../../Listing/handler/handleFiltering'
import { FORM_TAB } from '../tabMenu/model'
import { handleChangeTab } from '../../handler/handleChangeTab'
import { fetchOptionsDashboardFilter } from '../../handler/fetchOptionsDashboardFilter'
import { handleChange } from '../../handler/handleChange'
import { convertFormatDateTime } from '../../../../../../utils/lib'
import { QuestionContentForOverview } from '../testTab/components/QuestionContentForOverview'
import { StyledCard } from './components/ClassDrawer/Styled'
import { handleFetchDashboardData } from '../../handler/handleFetchDashboardData'
import { handlePersistFilter } from '../../handler/handlePersistFilter'

export const OverviewTab = () => {
  const dispatch = useDispatch()
  const { uuid } = useParams()
  const { formData, crudConfig, EtestDashboardTab, filterStateBelt } =
    useSelector(
      (state) => ({
        formData: state.crud.formData,
        crudConfig: state.crud.crudConfig,
        filterStateBelt: state.crud.filterStateBelt,
        EtestDashboardTab: state.crud.EtestDashboardTab,
      }),
      shallowEqual,
    )
  const { filterTotal, sort, order } = useSelector(
    (state) => ({
      filterTotal: state.table.filter.filterTotal,
      sort: state.table.table.sort,
      order: state.table.table.order,
    }),
    shallowEqual,
  )
  const dataDateTime = _.get(formData, 'dataDateTime', undefined)
  const questionsDatas = _.get(formData, 'qResult.questionsDatas', [])
  const filterFreeText = questionsDatas.filter((data) => {
    return data.questionType !== 'FREE_TEXT'
  })
  const scoreArray = filterFreeText.map((data) => {
    return data.correctAnswer
  })
  const lowestCorrect = Math.min(...scoreArray)
  const mostWrongQArray = filterFreeText.filter(
    (data) =>
      data.correctAnswer === lowestCorrect &&
      data.totalAnswer !== 0 &&
      data.totalAnswer - data.correctAnswer > 0,
  )

  const labelArr = []
  const valueArr = []

  const uniqueScoreData = _.get(formData, 'countUniqueScore', [])
  const dataArr = Object.keys(uniqueScoreData)
    .map((key) => {
      if (key !== 'null') return { key: key, value: uniqueScoreData[key] }
    })
    .sort(function (a, b) {
      return b.key - a.key
    })

  for (const data of dataArr) {
    if (data) {
      labelArr.push(data.key)
      valueArr.push(data.value)
    }
  }

  const dataSet = {
    data: valueArr,
    backgroundColor: '#4040AB',
  }

  useEffect(() => {
    dispatch(resetTable())
    handlePersistFilter(defaultFilter, filterStateBelt, true)
    if (!_.isEqual(crudConfig, initialCrudConfig)) {
      initialConfig(initialCrudConfig)
    }
    fetchOptionsDashboardFilter(filterItems(formData.typeOfETesting), uuid)
  }, [])

  useEffect(async () => {
    if (
      !_.isUndefined(crudConfig) &&
      crudConfig?.moduleId === 'ETESTING_DASHBOARD_OVERVIEW' &&
      EtestDashboardTab === 'overview'
    ) {
      if (uuid) {
        await handleFetchDashboardData(mutateFilterPropToBody, uuid)
      }
    }
  }, [crudConfig])
  useEffect(() => {
    setDashboardTable({ placeholder: 'ค้นหาด้วยคะแนน' })
  }, [formData, sort, order])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  console.log('render overviewTab')

  return (
    <StyledContainer>
      <Card sx={{ minWidth: 275 }}>
        <StyledContentBox sx={{ display: 'grid' }}>
          <StyledToolbar sx={{}}>
            <Typography variant="h6">ผลการทำข้อสอบ</Typography>
            <CFilterDrawer
              handleChange={handleChange}
              handleFiltering={() =>
                handleFiltering(
                  mutateFilterSateToProp,
                  mutateFilterPropToBody,
                  {
                    hideDownload: true,
                    isDashboardOverviewPage: true,
                    uuid: uuid,
                    placeholder: 'ค้นหาด้วยคะแนน',
                    url: filterEtestingDashboardOverview,
                  },
                )
              }
              filterItems={filterItems(formData.typeOfETesting)}
              defaultFilter={defaultFilter}
              isOverview={true}
            />

            <Box sx={{ display: 'flex', gap: '16px' }}>
              <Button
                data-testid={'btn-filter'}
                sx={{ height: 40, p: 1 }}
                variant="text"
                startIcon={<FilterListOutlined />}
                onClick={() => dispatch(setToggleFilterDrawer(true))}
              >
                ตัวกรอง {filterTotal != 0 && `(${filterTotal})`}
              </Button>
            </Box>
          </StyledToolbar>
          <FilterBox />
          <Typography variant="body2">
            ข้อมูลเมื่อวันที่{' '}
            {convertFormatDateTime({ value: dataDateTime, type: 'dateTime' }) ??
              dayjs(new Date()).format('DD/MM/YYYY 04:00')}
          </Typography>
          <DataRow>
            <InfoCard
              label="จำนวนผู้เรียนทั้งหมด"
              number={`${_.get(formData, 'countStudent', 0)}`}
              unit="คน"
              isOnClick={true}
              onClick={() => {
                dispatch(
                  openCourseDrawer({
                    header: 'จำนวนผู้เรียนทั้งหมด',
                    drawerType: 'formData',
                    courseType: 'allLearnerList',
                  }),
                )
              }}
            />
            <InfoCard
              label="คะแนนเฉลี่ย"
              number={_.get(formData, 'averageScoreText', '0/100')}
              unit="คะแนน"
              isLockWidth={true}
            />
            <InfoCard
              label="จำนวนคำถาม"
              number={`${_.get(formData, 'countQuestion', 0)}`}
              unit="คำถาม"
            />
          </DataRow>
          <Row sx={{ height: '362px', gap: '16px' }}>
            <Grid
              item
              container
              direction="column"
              sx={{ width: '46px', height: '362px', flexWrap: 'nowrap' }}
            >
              <Grid item xs={2}>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: '14px', padding: '4px' }}
                >
                  จำนวนผู้ได้คะแนน
                </Typography>
              </Grid>
              <Grid item xs={9} justifyContent="center" alignItems="center">
                <Divider
                  orientation="vertical"
                  variant="middle"
                  sx={{ width: '1px', margin: 'auto' }}
                />
              </Grid>
              <Grid item xs={1}>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: '14px', padding: '8px' }}
                >
                  คะแนน
                </Typography>
              </Grid>
            </Grid>
            <Box
              sx={{
                width: '50%',
                height: !_.isEmpty(labelArr) && '100%',
                margin: 'auto',
              }}
            >
              {_.isEmpty(labelArr) ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <InboxTwoTone color="text.lightGray" />
                  <Typography variant="body2" color="text.lightGray">
                    ไม่พบข้อมูล
                  </Typography>
                </Box>
              ) : (
                <BarChart dataSet={dataSet} labels={labelArr} />
              )}
            </Box>
            <Box sx={{ height: '362px', width: '50%' }}>
              <CContainer contentSx={{ mx: 0 }} cardSx={{ mt: 0 }} />
            </Box>
          </Row>
          <Divider variant="middle" />
          <CustomAccordion disableGutters={true}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h6">สถิติเพิ่มเติม</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ display: 'grid', gap: '16px' }}>
              <DataRow>
                <InfoCard
                  label="จำนวนที่ทำแบบทดสอบแล้ว"
                  number={`${_.get(formData, 'countDoTest', 0)}`}
                  unit="ครั้ง"
                  isOnClick={true}
                  onClick={() => {
                    dispatch(
                      openCourseDrawer({
                        header: 'จำนวนที่ทำแบบทดสอบแล้ว',
                        drawerType: 'formData',
                        courseType: 'doTest',
                      }),
                    )
                  }}
                />
                <InfoCard
                  label="จำนวนที่ยังไม่ทำแบบทดสอบ"
                  number={`${_.get(formData, 'countDontTest', 0)}`}
                  unit="ครั้ง"
                  isOnClick={true}
                  onClick={() => {
                    dispatch(
                      openCourseDrawer({
                        header: 'จำนวนที่ยังไม่ทำแบบทดสอบ',
                        drawerType: 'formData',
                        courseType: 'dontTest',
                      }),
                    )
                  }}
                />
                <InfoCard
                  label="จำนวนผ่าน"
                  number={`${_.get(formData, 'countPass', 0)}`}
                  unit="คน"
                  isOnClick={true}
                  onClick={() => {
                    dispatch(
                      openCourseDrawer({
                        header: 'จำนวนผ่าน',
                        drawerType: 'formData',
                        courseType: 'passLearner',
                      }),
                    )
                  }}
                />
                <InfoCard
                  label="จำนวนไม่ผ่าน"
                  number={`${_.get(formData, 'countNotPass', 0)}`}
                  unit="คน"
                  isOnClick={true}
                  onClick={() => {
                    dispatch(
                      openCourseDrawer({
                        header: 'จำนวนไม่ผ่าน',
                        drawerType: 'formData',
                        courseType: 'notPassLearner',
                      }),
                    )
                  }}
                />
              </DataRow>
              <DataRow>
                <InfoCard
                  label="จำนวนการทำข้อสอบ Max"
                  number={`${_.get(formData, 'countDoTestMax', 0)}`}
                  unit="ครั้ง"
                />
                <InfoCard
                  label="จำนวนการทำข้อสอบ Min"
                  number={`${_.get(formData, 'countDoTestMin', 0)}`}
                  unit="ครั้ง"
                />
                <InfoCard
                  label="จำนวนครั้ง Avg"
                  number={`${_.get(formData, 'countDoTestAverage', 0)}`}
                  unit="ครั้ง"
                />
              </DataRow>
            </AccordionDetails>
          </CustomAccordion>
        </StyledContentBox>
      </Card>
      <Card sx={{ marginTop: '16px' }}>
        <StyledContentBox sx={{ display: 'grid' }}>
          <DataRow>
            <Typography variant="h6">คำถามที่ตอบผิดมากที่สุด</Typography>
            <IconButton
              onClick={() => dispatch(handleChangeTab(FORM_TAB.testList))}
              sx={{ padding: '4px', backgroundColor: '#4976BA14' }}
            >
              <KeyboardArrowRightRounded />
            </IconButton>
          </DataRow>
          {_.isEmpty(mostWrongQArray) ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <InboxTwoTone color="text.lightGray" />
              <Typography variant="body2" color="text.lightGray">
                ไม่พบคำถามที่ตอบผิด
              </Typography>
            </Box>
          ) : (
            <>
              <InfoCard
                label="จำนวนการผิดจากคำตอบทั้งหมด"
                number={`${
                  _.get(mostWrongQArray[0], 'totalAnswer', 0) -
                  _.get(mostWrongQArray[0], 'correctAnswer', 0)
                }/${_.get(mostWrongQArray[0], 'totalAnswer', 0)}`}
                unit="ครั้ง"
              />
              {mostWrongQArray.map((_, index) => {
                return (
                  <StyledCard
                    id="list-table"
                    sx={{
                      padding: '12px 16px 12px 16px',
                      gap: '24px',
                      margin: '0',
                    }}
                    key={index}
                  >
                    <QuestionContentForOverview
                      questionsDatas={mostWrongQArray}
                      selected={index}
                    />
                  </StyledCard>
                )
              })}
            </>
          )}
        </StyledContentBox>
      </Card>
      <ClassDrawer />
    </StyledContainer>
  )
}
