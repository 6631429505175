import {
  closeDialog,
  loadingDialog,
  openDialog,
  setDisableButton,
  setEnableButton,
} from '../../../../redux/slices/dialog'
import _ from 'lodash'
import { E_EXAM_TYPE } from '../../../../constants/eExamination'
import { createScheduleExamination } from '../../../../services/eExamination'
import { clearDateRangeSearch, fetchExamListFilter } from '../../List/event'
import { store } from '../../../../App'
import {
  setSearchText,
  setSearchDate,
  setFilterProp,
  resetTablePage,
  setSelected,
  setChangeRowsPerPage,
  setOrder,
  setDefaultSort,
} from '../../../../redux/slices/table'
import { setInitialExamList } from '../../../../redux/slices/eExamination/list'

export const handleCloseDialog = () => (dispatch) => {
  dispatch(closeDialog())
}

export const handleEnableButton = (e) => (dispatch) => {
  if (e.target.checked) dispatch(setEnableButton())
  else dispatch(setDisableButton())
}

export const handleNumber = (index) => {
  if (index > 0 && index < 10) return `0${index}`
  return index
}

export const resetFilter = () => (dispatch) => {
  const { table } = store.getState().table
  dispatch(setInitialExamList(true))
  dispatch(setSearchText(''))
  dispatch(setSearchDate(''))
  dispatch(clearDateRangeSearch())
  dispatch(setDefaultSort({ state: 0, id: '', active: false }))
  dispatch(setOrder({ order: 'desc', sort: 'updatedAt' }))
  dispatch(setFilterProp(null))
  dispatch(resetTablePage())
  dispatch(setSelected([]))
  dispatch(
    setChangeRowsPerPage({
      defaultRowsPerPage: 20,
      limit: 20,
      rowsPerPage: { label: '20', value: 20 },
    }),
  )
  dispatch(
    fetchExamListFilter({
      method: 'initial',
      table,
      page: 1,
      statusList: [],
      filterText: null,
      keyword: '',
    }),
  )
  dispatch(closeDialog())
}

export const handleSubmitAdd =
  (uploadResult, timeList, eExamType, associationList, companyList) =>
  async (dispatch) => {
    dispatch(loadingDialog())
    const result = _.get(uploadResult, 'result', [])
    let body = result.filter((item) => item.status === 'SUCCESS')
    body = _.map(body, (item) => {
      const findTime = _.find(timeList, (i) => {
        return _.get(i, 'timeName') == _.get(item, 'time')
      })
      const findAssociation = _.find(associationList, (i) => {
        return _.get(i, 'provinceName') == _.get(item, 'provinceName')
      })
      const findCompany = _.find(companyList, (i) => {
        return (
          _.get(i, 'academyStationName') == _.get(item, 'academyStationName')
        )
      })

      return {
        ...item,
        time: _.get(findTime, 'uuid'),
        association:
          eExamType == E_EXAM_TYPE.ASSOCIATION || eExamType == E_EXAM_TYPE.OTHER
            ? _.get(findAssociation, 'uuid')
            : null,
        station:
          eExamType == E_EXAM_TYPE.COMPANY ? _.get(findCompany, 'uuid') : null,
        otherName:
          eExamType == E_EXAM_TYPE.OTHER ? _.get(item, 'otherName') : null,
        isUpload: true,
        defaultScheduleName: [
          E_EXAM_TYPE.ASSOCIATION,
          E_EXAM_TYPE.COMPANY,
        ].includes(eExamType)
          ? _.get(item, 'defaultScheduleName')
          : null,
      }
    })

    const response = await dispatch(createScheduleExamination(body))
    if (!response?.error) {
      dispatch(
        openDialog({
          type: 'success',
          title: 'สำเร็จ',
          message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          handleConfirm: () => {
            dispatch(resetFilter())
          },
          handleCancel: () => {
            dispatch(resetFilter())
          },
        }),
      )
    } else {
      dispatch(
        openDialog({
          type: 'fail',
          title: 'ผิดพลาด',
          message: 'ระบบขัดข้อง',
          handleConfirm: () => {
            dispatch(resetFilter())
          },
          handleCancel: () => {
            dispatch(resetFilter())
          },
        }),
      )
    }
  }
