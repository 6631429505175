import { examinationTemplateDownload } from '../../../utils/apiPath'
import {
  headCells,
  headCellsCompany,
  headCellsOther,
  SCHEDULE_STATUS_TEXT_TH,
  E_EXAM_TYPE,
  SCHEDULE_LIST_STATUS_COLOR,
} from '../../../constants/eExamination'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setStatusFilter,
  startLoading,
  stopLoading,
  setFilterTotal,
  setSearchDate,
  setChangeRowsPerPage,
  setSelected,
  setOrder,
  setDefaultSort,
  setSearchDateRange,
} from '../../../redux/slices/table'
import { downloadFile } from '../../../services/util'
import { convertFormatDateTime, validatePermission } from '../../../utils/lib'
import { initialState } from '../../../redux/slices/table/model'
import {
  deleteExaminationSchedule,
  getExaminationTime,
  fetchAllExaminationAssociationList,
  fetchAllExaminationCompanyList,
} from '../../../services/eExamination'
import _ from 'lodash'
import {
  setExamList,
  setExaminationTime,
  setAssociationList,
  setCompanyList,
  setInitialExamList,
} from '../../../redux/slices/eExamination/list'
import { store } from '../../../App'
import {
  closeDialog,
  loadingDialog,
  openDialog,
  stopLoadingDialog,
} from '../../../redux/slices/dialog'
import { SCHEDULE_STATUS } from '../../../constants/eExamination'
import dayjs from 'dayjs'
import callAxios from '../../../utils/baseService'
import { examinationListFilter } from '../../../utils/apiPath'
import { EXAMINATION_SETTING, PERMISSION } from '../../../constants/examination'
import { setFieldValue } from 'src/redux/slices/crud'

export const onDownload = (selected, sort, order) => async (dispatch) => {
  dispatch(startLoading())
  const { eExamType } = store.getState().eExamList
  const body = {
    order: order.toUpperCase(),
    sort: sort,
    type: 'CUSTOM',
    list: selected,
    schedule: eExamType,
  }

  await dispatch(
    downloadFile({
      url: examinationTemplateDownload,
      body: body,
      fileName: `รายการตารางสอบ.xlsx`,
    }),
  )
  dispatch(stopLoading())
}

export const fetchExamListFilter = (props) => async (dispatch) => {
  dispatch(startLoading())
  const {
    method,
    table,
    page,
    statusList,
    filterProp,
    keyword,
    dateKeyword,
    dateKeywordEnd,
    examinationPermissions,
    // searchType,
  } = props
  const isInitial = method == 'initial'
  const tableProps = isInitial
    ? {
        ...initialState.table,
        limit: '20',
        rowsPerPage: { label: '20', value: 20 },
      }
    : table
  const filter = isInitial ? null : filterProp
  const { eExamType } = store.getState().eExamList
  const search = isInitial ? '' : keyword
  const dateSearch = isInitial
    ? ''
    : dateKeyword && dateKeyword != ''
    ? dayjs(dateKeyword).format(window.__env__.REACT_APP_DATE_DB) ||
      table.handleDateSearch ||
      ''
    : _.get(filter, 'startDate', '')
  const dateSearchEndDate = isInitial
    ? ''
    : dateKeywordEnd && dateKeywordEnd != ''
    ? dayjs(dateKeywordEnd).format(window.__env__.REACT_APP_DATE_DB) ||
      table.handleDateSearch ||
      ''
    : _.get(filter, 'endDate', '')

  const headCellsData =
    eExamType === E_EXAM_TYPE.COMPANY
      ? headCellsCompany
      : eExamType === E_EXAM_TYPE.OTHER
      ? headCellsOther
      : headCells

  const actionExamIndex = headCellsData.findIndex((x) => x.id === 'actionExam')
  const user = JSON.parse(localStorage.getItem('user'))
  const hasDeletePermission = validatePermission({
    user,
    moduleType: EXAMINATION_SETTING,
    permission: [PERMISSION.DELETE],
  })
  const hasCreatePermission = validatePermission({
    user,
    moduleType: EXAMINATION_SETTING,
    permission: [PERMISSION.CREATE],
  })
  const hasViewPermission = validatePermission({
    user,
    moduleType: EXAMINATION_SETTING,
    permission: [PERMISSION.VIEW],
  })
  const actionObj = {
    hideView: hasViewPermission ? false : true,
    hideEdit: hasCreatePermission ? false : true,
    hideDelete: hasDeletePermission ? false : true,
  }
  _.assign(headCellsData[actionExamIndex], actionObj)

  const checkSortValue = _.find(headCellsData, (h) => {
    return h.id === _.get(tableProps, 'sort')
  })
  const body = {
    search: _.defaultTo(search, ''), //ค้นหา
    region: _.get(filter, 'region', []), //ค้นหา ภาคโดยการส่ง UUID มา
    provinceName: _.get(filter, 'provinceName', ''), //ค้นหาชื่อ สนามสอบ (สำหรับ สมาคม, อื่นๆ)
    stationName: _.get(filter, 'stationName', ''), //ค้นหาชื่อ สนามสอบ (สำหรับบริษัท)
    otherName: _.get(filter, 'otherName', ''), //ค้นหาชื่อ สนามสอบ (สำหรับ อื่นๆ)
    scheduleName: _.get(filter, 'scheduleName', ''),
    startDate: dateSearch, //วันที่เริ่มสอบ
    endDate: dateSearchEndDate, //วันที่สิ้นสุดการสอบ
    startDateRegister: _.get(filter, 'startDateRegister', ''), //วันที่เริ่มเปิดรับสมัคร
    endDateRegister: _.get(filter, 'endDateRegister', ''), //วันที่สิ้นสุดเปิดรับสมัคร
    startOfDateRegister: _.get(filter, 'startOfDateRegister', ''), //วันที่เริ่มปิดรับสมัคร
    endOfDateRegister: _.get(filter, 'endOfDateRegister', ''), //วันที่สิ้นสุดปิดรับสมัคร
    numberOfPeople: +_.get(filter, 'numberOfPeople', -1), //จำนวนผู้สมัครสอบ
    createdBy: _.get(filter, 'createdBy', ''), //สร้างโดย
    updatedBy: _.get(filter, 'updatedBy', ''), //แก้ไขล่าสุดโดย
    status: _.defaultTo(statusList, []), // สถานะ OPEN_REGISTER,IN_PROGRESS,CANCEL,CLOSED_REGISTER
    limit: _.get(tableProps, 'limit', '20'),
    order: _.get(tableProps, 'order', 'DESC').toString().toUpperCase(),
    page: _.defaultTo(page, 1),
    sort: _.isEmpty(checkSortValue)
      ? 'updatedAt'
      : _.get(tableProps, 'sort', 'updatedAt'), // stationName | stationNameEN | locationCode | updatedAt
    schedule: eExamType, //ASSOCIATION, COMPANY, OTHER
    time: _.get(filter, 'time', []),
  }

  await callAxios
    .post(examinationListFilter, body)
    .then(({ data }) => {
      let results = _.get(data, 'result', [])
      const totalCount = _.get(data, 'totalCount', 0)

      results = _.map(results, (item) => {
        return {
          ...item,
          examStatus: SCHEDULE_STATUS_TEXT_TH[_.get(item, 'status')],
          textColor: SCHEDULE_LIST_STATUS_COLOR[_.get(item, 'status')],
          status: ['CANCEL', 'COMPLETED'].includes(_.get(item, 'status'))
            ? 'INACTIVE'
            : 'ACTIVE',
          proviceName: _.get(item, 'other.association.provinceName'),
          regionName:
            eExamType === E_EXAM_TYPE.ASSOCIATION
              ? _.get(item, 'association.region.regionName')
              : _.get(item, 'other.association.region.regionName'),
          examStation:
            eExamType === E_EXAM_TYPE.COMPANY
              ? _.get(item, 'station.academyStationName')
              : eExamType === E_EXAM_TYPE.ASSOCIATION
              ? _.get(item, 'association.provinceName')
              : _.get(item, 'other.otherName'),
          examDate: `${convertFormatDateTime({
            value: _.get(item, 'date'),
            type: 'date',
          })} ${_.get(item, 'time.startTime')} - ${_.get(
            item,
            'time.endTime',
          )}`,
          examStartRegisterDate: `${convertFormatDateTime({
            value: _.get(item, 'startDateRegister'),
            type: 'date',
          })} ${_.get(item, 'startTimeRegister')} `,
          examEndRegisterDate: `${convertFormatDateTime({
            value: _.get(item, 'endDateRegister'),
            type: 'date',
          })} ${_.get(item, 'endTimeRegister')}`,
          numberOfPeople: `${item.countStudent ?? 0}/${
            item.numberOfPeople ?? 0
          }`,
        }
      })
      dispatch(setExamList(results))
      let handleSearch = (text) =>
        dispatch(
          handleQuickSearch(
            tableProps,
            text,
            dateSearch,
            dateSearchEndDate,
            filter,
            examinationPermissions,
          ),
        )
      if (actionObj?.hideView) handleSearch = ''
      let handleDateSearch = (text) =>
        dispatch(
          handleQuickDateSearch(
            tableProps,
            text,
            search,
            filter,
            examinationPermissions,
          ),
        )
      if (actionObj?.hideView) handleDateSearch = ''
      let onDownloadFunc = (selected, sort, order) =>
        dispatch(onDownload(selected, sort, order))
      if (actionObj?.hideView) onDownloadFunc = ''
      dispatch(
        setInitialTable({
          rows: results,
          allCount: totalCount,
          headCells: headCellsData,
          placeholder: 'ค้นหาสนามสอบ',
          searchKey: 'name',
          status: statusList,
          handleSearch: handleSearch,
          handleDateSearch: handleDateSearch,
          onDownload: onDownloadFunc,
          onDelete: (row) =>
            dispatch(
              handleDeleteModule(row, tableProps, examinationPermissions),
            ),
        }),
      )
    })
    .catch((err) => {
      console.log(err)
    })

  if (isInitial) {
    const responseTime = await dispatch(getExaminationTime())
    const responseAssociation = await dispatch(
      fetchAllExaminationAssociationList(),
    )
    const responseCompany = await dispatch(fetchAllExaminationCompanyList())
    const resultsTime = _.get(responseTime, 'payload.data.result', [])
    const resultsAssociation = _.get(
      responseAssociation,
      'payload.data.result',
      [],
    )
    const resultsCompany = _.get(responseCompany, 'payload.data.result', [])
    dispatch(setExaminationTime(resultsTime))
    dispatch(setAssociationList(resultsAssociation))
    dispatch(setCompanyList(resultsCompany))
  }

  isInitial && dispatch(setInitialExamList(false))
  dispatch(stopLoading())
}

export const onFilterClick = (table, filter) => (dispatch) => {
  dispatch(setSearchText(''))
  dispatch(setSearchDate(''))
  dispatch(clearDateRangeSearch())
  const statusList = []

  if (filter.statusChecked) {
    if (filter.status.inProgressChecked) {
      statusList.push(SCHEDULE_STATUS.IN_PROGRESS)
    }
    if (filter.status.cancelChecked) {
      statusList.push(SCHEDULE_STATUS.CANCEL)
    }
    if (filter.status.openRegisterChecked) {
      statusList.push(SCHEDULE_STATUS.OPEN_REGISTER)
    }
    if (filter.status.closedRegisterChecked) {
      statusList.push(SCHEDULE_STATUS.CLOSED_REGISTER)
    }
    if (filter.status.inExamChecked) {
      statusList.push(SCHEDULE_STATUS.IN_EXAM)
    }
    if (filter.status.successChecked) {
      statusList.push(SCHEDULE_STATUS.SUCCESS)
    }
    if (filter.status.completedChecked) {
      statusList.push(SCHEDULE_STATUS.COMPLETED)
    }
  }
  dispatch(setStatusFilter(statusList))

  const timeList = []
  if (!_.isEmpty(filter.time) && filter.timeChecked) {
    for (const [key, value] of Object.entries(filter.time)) {
      if (value) timeList.push(key)
    }
  }

  const { eExamType } = store.getState().eExamList
  const filterProp = {
    search: '', //ค้นหา
    regionChecked: filter.regionChecked,
    region: filter.regionChecked ? _.get(filter, 'region', []) : [], //ค้นหา ภาคโดยการส่ง UUID มา
    provinceName:
      filter.examinationFieldChecked && eExamType === E_EXAM_TYPE.ASSOCIATION
        ? _.get(filter, 'examinationField', '')
        : filter.provinceFieldChecked && eExamType === E_EXAM_TYPE.OTHER
        ? _.get(filter, 'provinceField', '')
        : '', //ค้นหาชื่อ สนามสอบ (สำหรับ สมาคม, อื่นๆ)
    stationName:
      filter.examinationFieldChecked && eExamType === E_EXAM_TYPE.COMPANY
        ? _.get(filter, 'examinationField', '')
        : '', //ค้นหาชื่อ สนามสอบ (สำหรับบริษัท)
    otherName:
      filter.examinationFieldChecked && eExamType === E_EXAM_TYPE.OTHER
        ? _.get(filter, 'examinationField', '')
        : '', //ค้นหาชื่อ สนามสอบ (สำหรับ อื่นๆ)
    scheduleName:
      filter.scheduleNameChecked &&
      [E_EXAM_TYPE.ASSOCIATION, E_EXAM_TYPE.COMPANY].includes(eExamType)
        ? _.get(filter, 'scheduleName', '')
        : '',
    startDate:
      filter.examDateChecked && _.get(filter, 'examDate.startDate', '') !== ''
        ? dayjs(_.get(filter, 'examDate.startDate', '')).format(
            window.__env__.REACT_APP_DATE_DB,
          )
        : '', //วันที่เริ่มสอบ
    endDate:
      filter.examDateChecked && _.get(filter, 'examDate.toDate', '') !== ''
        ? dayjs(_.get(filter, 'examDate.toDate', '')).format(
            window.__env__.REACT_APP_DATE_DB,
          )
        : '', //วันที่สิ้นสุดการสอบ
    startDateRegister:
      filter.startDateRegisterChecked &&
      _.get(filter, 'startDateRegister.startDate', '') !== ''
        ? dayjs(_.get(filter, 'startDateRegister.startDate', '')).format(
            window.__env__.REACT_APP_DATE_DB,
          )
        : '', //วันที่เริ่มเปิดรับสมัคร
    endDateRegister:
      filter.startDateRegisterChecked &&
      _.get(filter, 'startDateRegister.toDate', '') !== ''
        ? dayjs(_.get(filter, 'startDateRegister.toDate', '')).format(
            window.__env__.REACT_APP_DATE_DB,
          )
        : '', //วันที่สิ้นสุดเปิดรับสมัคร
    startOfDateRegister:
      filter.endDateRegisterChecked &&
      _.get(filter, 'endDateRegister.startDate', '') !== ''
        ? dayjs(_.get(filter, 'endDateRegister.startDate', '')).format(
            window.__env__.REACT_APP_DATE_DB,
          )
        : '', //วันที่เริ่มปิดรับสมัคร
    endOfDateRegister:
      filter.endDateRegisterChecked &&
      _.get(filter, 'endDateRegister.toDate', '') !== ''
        ? dayjs(_.get(filter, 'endDateRegister.toDate', '')).format(
            window.__env__.REACT_APP_DATE_DB,
          )
        : '', //วันที่สิ้นสุดปิดรับสมัคร
    numberOfPeople: _.isEmpty(filter.peopleNumber)
      ? -1
      : filter.peopleNumberChecked
      ? _.get(filter, 'peopleNumber', -1)
      : -1, //จำนวนผู้สมัครสอบ
    createdBy: filter.createdByChecked ? _.get(filter, 'createdBy', '') : '', //สร้างโดย
    updatedBy: filter.updatedByChecked ? _.get(filter, 'updatedBy', '') : '', //แก้ไขล่าสุดโดย
    schedule: eExamType,
    time: timeList,
  }

  dispatch(setFilterProp(filterProp))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())
  dispatch(
    fetchExamListFilter({
      method: 'filter',
      table,
      page: 1,
      statusList,
      filterProp,
    }),
  )
}

export const handleQuickSearch =
  (
    table,
    text,
    dateValue,
    dateSearchEndDate,
    filterProp,
    examinationPermissions,
  ) =>
  (dispatch) => {
    dispatch(setSearchText(text))
    // dispatch(setFilterProp(null))
    dispatch(resetTablePage())
    dispatch(
      fetchExamListFilter({
        method: 'search',
        searchType: 'dateField',
        table,
        page: 1,
        statusList: [],
        filterText: null,
        keyword: text,
        dateKeyword: dateValue ? dateValue : '',
        dateKeywordEnd: dateSearchEndDate ? dateSearchEndDate : '',
        filterProp: filterProp,
        examinationPermissions,
      }),
    )
  }

export const handleQuickDateSearch =
  (table, dateValue, text, filterProp, examinationPermissions) =>
  (dispatch) => {
    const { isFunctionClear } = store.getState().crud
    if (isFunctionClear) {
      dispatch(
        setFieldValue({
          key: 'isFunctionClear',
          value: false,
        }),
      )
      return false
    }
    const startDate = dayjs(dateValue?.startDate).isValid()
      ? dateValue?.startDate
      : ''
    const endDate = dayjs(dateValue?.endDate).isValid()
      ? dateValue?.endDate
      : ''
    // dispatch(setSearchDate(dateValue))
    dispatch(
      setSearchDateRange({
        startDate: dateValue?.startDate,
        endDate: dateValue?.endDate,
        displayDate: dateValue?.display,
      }),
    )
    // dispatch(setFilterProp(null))
    dispatch(resetTablePage())
    dispatch(
      fetchExamListFilter({
        method: 'search',
        searchType: 'dateField',
        table,
        page: 1,
        statusList: [],
        filterText: null,
        dateKeyword: startDate,
        dateKeywordEnd: endDate,
        keyword: text,
        filterProp: filterProp,
        examinationPermissions,
      }),
    )
  }

export const handleDeleteModule =
  (row, table, examinationPermissions) => async (dispatch) => {
    dispatch(
      openDialog({
        title: 'ยืนยันลบข้อมูล',
        message: 'คุณต้องการลบรายการใช่หรือไม่',
        colorDisagreeText: 'error',
        colorAgreeText: 'error',
        agreeText: 'ลบ',
        disagreeText: 'ยกเลิก',
        handleConfirm: () =>
          dispatch(
            handleConfirmDelete(row.uuid, table, examinationPermissions),
          ),
      }),
    )
  }

export const resetFilter = (table, examinationPermissions) => (dispatch) => {
  dispatch(setInitialExamList(true))
  dispatch(setSearchText(''))
  dispatch(setSearchDate(''))
  dispatch(clearDateRangeSearch())
  dispatch(setDefaultSort({ state: 0, id: '', active: false }))
  dispatch(setOrder({ order: 'desc', sort: 'updatedAt' }))
  dispatch(setFilterProp(null))
  dispatch(resetTablePage())
  dispatch(setSelected([]))
  dispatch(
    setChangeRowsPerPage({
      defaultRowsPerPage: 20,
      limit: 20,
      rowsPerPage: { label: '20', value: 20 },
    }),
  )
  dispatch(
    fetchExamListFilter({
      method: 'initial',
      table,
      page: 1,
      statusList: [],
      filterText: null,
      keyword: '',
      examinationPermissions,
    }),
  )
  dispatch(stopLoadingDialog())
  dispatch(closeDialog())
}

export const handleConfirmDelete =
  (uuid, table, examinationPermissions) => async (dispatch) => {
    dispatch(startLoading())
    dispatch(loadingDialog())
    const response = await dispatch(deleteExaminationSchedule({ uuid }))
    if (!response?.error) {
      dispatch(
        openDialog({
          type: 'success',
          title: 'สำเร็จ',
          message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          handleConfirm: () => {
            dispatch(resetFilter(table, examinationPermissions))
          },
        }),
      )
    } else {
      dispatch(
        openDialog({
          type: 'fail',
          title: 'ผิดพลาด',
          message:
            'ไม่สามารถบันทึกข้อมูลได้ เนื่องจากพบปัญหาบางอย่าง โปรดลองใหม่อีกครั้ง',
          handleConfirm: () => {
            dispatch(resetFilter(table, examinationPermissions))
          },
        }),
      )
    }
  }

export const clearDateRangeSearch = () => (dispatch) => {
  dispatch(setSearchDateRange(null))
  dispatch(
    setFieldValue({
      key: 'isFunctionClear',
      value: true,
    }),
  )
  document.getElementById('clear-date-range-button')?.click()
  document.body.click()
}
