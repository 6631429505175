import { Box, Button, Card, CardContent, Typography } from '@mui/material'
import { ToggleContent } from '../ToggleContent'
import { axisLabel, partTitle } from '../../models/partModel'
import { shallowEqual } from 'react-redux'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import BarChart from 'src/components/Chart/BarChart'
import { DataCard } from '../ModuleCard/DataCard'
import { handleClickViewButton } from '../../handler/handleClickViewButton'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { questionTypeText } from '../../constants/type'
import { generateGradientColors } from '../../handler/handleSetColors'
import AnswerTable from '../AnswerTable'

const OtherEva = ({ isPrinting = false }) => {
  const { part4Eva } = useSelector(
    (state) => ({
      part4Eva: state.crud?.eEvaluationDashboard?.overview?.part4Eva,
    }),
    shallowEqual,
  )
  return (
    <Box mb={3}>
      <Card>
        <CardContent>
          <ToggleContent
            title={partTitle.part4}
            contentId="otherEva"
            alwaysOpen={true}
            hideIcon={true}
          >
            {_.defaultTo(part4Eva, []).map((item, index) => (
              <SubToggleContent
                key={'otherEva-' + index}
                data={item}
                dataLength={part4Eva.length}
                index={index}
                isPrinting={isPrinting}
              />
            ))}
          </ToggleContent>
        </CardContent>
      </Card>
    </Box>
  )
}

const SubToggleContent = ({ data, dataLength, index, isPrinting }) => {
  const answer = data?.answer ?? []
  const labels = answer.map((item) => item.no)
  let bgColors = []
  let chart = []
  if (data.type !== questionTypeText.FREE_TEXT) {
    bgColors =
      answer.length > 1
        ? generateGradientColors(answer.length ?? 0)
        : answer.length == 1
        ? ['#B2DFDB']
        : []
    chart = answer.map((item) => item.count)
  }

  const moduleData = preparedModuleData(data)
  const totalAnswer = data.totalAnswer ?? 0
  return (
    <Box key={`sub-toggle-$${data.title}-` + index}>
      {isPrinting && index > 0 && <br />}
      <ToggleContent
        title={data.title}
        type={data.type}
        contentId={data.contentId}
        BoxSx={
          dataLength === index + 1
            ? ''
            : { borderBottom: 'solid 1px #00000012' }
        }
        alwaysOpen={isPrinting ? true : false}
        hideIcon={isPrinting ? true : false}
        isTruncated={true}
        isPrinting={isPrinting}
        alignItems={'start'}
      >
        <Box>
          {[questionTypeText.FREE_TEXT, questionTypeText.RATING].includes(
            data.type,
          ) && (
            <>
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                className={`${isPrinting ? 'hide-pdf' : ''}`}
              >
                <Button
                  data-testid="btn-preview"
                  variant="outlined"
                  size="m"
                  endIcon={<ChevronRightIcon color="primary" />}
                  onClick={() => {
                    handleClickViewButton('part4', index)
                  }}
                >
                  ดูรายละเอียดคำตอบ
                </Button>
                <Typography variant="body1">
                  (ทั้งหมด {totalAnswer} คำตอบ)
                </Typography>
              </Box>
            </>
          )}
          <Box
            display="flex"
            flexDirection={'column'}
            gap={2}
            alignItems={'start'}
            width={'100%'}
          >
            <DataCard
              dataList={moduleData}
              totalModule={''}
              border={'none'}
              padding={0}
            />
          </Box>
          {data.type !== questionTypeText.FREE_TEXT && (
            <>
              <Box
                minHeight="250px"
                sx={{
                  width: '100%',
                  maxWidth: isPrinting ? '650px' : '100%',
                }}
              >
                <BarChart
                  dataSet={{
                    data: chart,
                    backgroundColor: bgColors,
                  }}
                  labels={labels}
                  height={'300px'}
                  showXAxisLabel={true}
                  showYAxisLabel={true}
                  xAxisLabel={axisLabel.x}
                  yAxisLabel={axisLabel.y}
                  showPercent={true}
                  eleId={`part4-${data.title}-${index}`}
                />
              </Box>
              <Box
                sx={{
                  width: '100%',
                  maxWidth: isPrinting ? '100%' : '100%',
                }}
              >
                <AnswerTable answers={answer} />
              </Box>
            </>
          )}
        </Box>
      </ToggleContent>
      <div className="pagebreak-after"> </div>
    </Box>
  )
}

const preparedModuleData = (item) => {
  const reponseUsers = item?.responseUserList ?? []
  const defaultDataCard = [
    {
      label: 'จำนวนผู้เข้าอบรม',
      labelVariant: 'body2',
      number: item?.attendeeCount ?? 0,
      hidePercent: true,
      unit: 'คน',
      width: '200px',
      hideClick: true,
    },
    {
      label: 'จำนวนผู้ทำแบบประเมิน',
      labelVariant: 'body2',
      background: '#4976BA14',
      number: reponseUsers?.length > 0 ? reponseUsers.length : 0,
      hidePercent: true,
      unit: 'คน',
      width: '200px',
      hideClick: true,
    },
  ]
  if (item.type === 'Rating') {
    const drawerQa = item?.drawerQA ?? []
    const sumPercentage = _.sumBy(drawerQa, 'percent')
    const percent =
      sumPercentage > 0
        ? Math.floor((sumPercentage / drawerQa.length) * 100) / 100
        : 0
    defaultDataCard.push({
      label: 'ภาพรวมที่ได้',
      labelVariant: 'body2',
      background: '#4976BA14',
      number: `${item?.overallPercentage ?? percent ?? 0} %`,
      isString: true,
      hidePercent: true,
      unit: '',
      width: '200px',
      hideClick: true,
    })
  }
  return defaultDataCard
}

export default OtherEva
