import { store } from '../../../../../../App'
import { toggleEvaluationDrawer } from './handleClickOpenDrawer'
import { setFieldValue } from '../../../../../../redux/slices/crud'
import { partTitle } from '../models/partModel'
import _ from 'lodash'
import dayjs from 'dayjs'
import { section } from 'src/constants/eEvaluation'
import callAxios from 'src/utils/baseService'
import { eEvaluationDashboardDrawerDetail } from 'src/utils/apiPath'
import { getQueryParams } from 'src/utils/lib'

export const handleClickViewButton = async (type, index = null) => {
  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: true,
    }),
  )

  const startDate = getQueryParams('startDate')
  const finishDate = getQueryParams('finishDate')
  const { eEvaluationDashboard } = store.getState().crud
  const eEvaluationDetail = store.getState().eEvaluationDetail
  const eEvaluationVersion = eEvaluationDetail?.result?.uuid || ''
  const isManageClass =
    eEvaluationDashboard?.filtersDrawer.isManageClass || false
  const body = {
    eEvaluationVersion: eEvaluationVersion,
    startDate: isManageClass
      ? ''
      : eEvaluationDashboard?.filtersDrawer?.isFilterUserResponseDate
      ? eEvaluationDashboard?.filtersDrawer?.dateFilterStartDate ?? ''
      : eEvaluationDashboard?.filtersDrawer?.dateFilterStartDate ??
        dayjs().startOf('month').format('YYYY-MM-DD'),
    endDate: isManageClass
      ? ''
      : eEvaluationDashboard?.filtersDrawer?.isFilterUserResponseDate
      ? eEvaluationDashboard?.filtersDrawer?.dateFilterFinishDate ?? ''
      : eEvaluationDashboard?.filtersDrawer?.dateFilterFinishDate ??
        dayjs().startOf('month').format('YYYY-MM-DD'),
    userResponseDateStart: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.userResponseDateStart,
      dayjs().format('YYYY-MM-DD'),
    ),
    userResponseDateEnd: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.userResponseDateEnd,
      dayjs().format('YYYY-MM-DD'),
    ),
    courseName: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.courseName,
      '',
    ),
    trainingDateStartDate: isManageClass
      ? startDate
      : _.defaultTo(
          eEvaluationDashboard?.filtersDrawer?.trainingDateStartDate,
          '',
        ),
    trainingDateFinishDate: isManageClass
      ? finishDate
      : _.defaultTo(
          eEvaluationDashboard?.filtersDrawer?.trainingDateFinishDate,
          '',
        ),
    trainerName: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.trainerName,
      '',
    ),
    distribution: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.distribution,
      '',
    ),
    trainingPlanUuid: '',
    courseCode: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.courseCode,
      '',
    ),
    department: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.department,
      '',
    ),
    station: _.defaultTo(eEvaluationDashboard?.filtersDrawer?.station, ''),
    section: '',
    trainerUuid: '',
    questionUuid: '',
    questionType: '',
  }
  if (type === 'part1') {
    setEvaDrawer('title', partTitle.part1)
    setEvaDrawer('drawerType', 'part1')
    setEvaDrawer('dataType', 'Rating')

    body.section = section.COURSE_ASSESSMENTS
  } else if (type === 'part2') {
    const { eEvaluationDashboard } = store.getState().crud
    const trainerData = _.get(
      eEvaluationDashboard,
      `overview.trainerEva.[${index}]`,
      null,
    )
    setEvaDrawer('title', `${partTitle.part2} : ${trainerData?.title ?? ''}`)
    setEvaDrawer('drawerType', 'part2')
    setEvaDrawer('dataType', 'Rating')

    body.section = section.LECTURER_ASSESSMENTS
    body.trainerUuid = trainerData?.trainerUuid || trainerData?.staffUuid
  } else if (type === 'part3') {
    setEvaDrawer('title', partTitle.part3)
    setEvaDrawer('drawerType', 'part3')
    setEvaDrawer('dataType', 'Rating')

    body.section = section.COURSE_MANAGEMENT_ASSESSMENTS
  } else if (type === 'part4') {
    const { eEvaluationDashboard } = store.getState().crud
    const part4EvaData = _.get(
      eEvaluationDashboard,
      `overview.part4Eva.[${index}]`,
      null,
    )
    const type = part4EvaData?.type
    setEvaDrawer('title', part4EvaData?.title)
    setEvaDrawer('drawerType', 'part4')
    setEvaDrawer('dataType', type)
    setEvaDrawer('part4.selectedNo', 1)
    setEvaDrawer('part4.type', type)
    setEvaDrawer('part4.uuid', part4EvaData.uuid)

    body.section = section.QUESTIONS
    body.questionType = type.toUpperCase().replace(/ /g, '_')
    body.questionUuid = part4EvaData.uuid
  }

  await callAxios
    .post(eEvaluationDashboardDrawerDetail, body)
    .then(({ data }) => {
      setEvaDrawer(`${type}.data`, data)
      store.dispatch(
        setFieldValue({
          key: 'isLoading',
          value: false,
        }),
      )
      toggleEvaluationDrawer(true)
    })
    .catch((err) => {
      console.log(err)
      store.dispatch(
        setFieldValue({
          key: 'isLoading',
          value: false,
        }),
      )
    })
}

export const setEvaDrawer = (key, value) => {
  store.dispatch(
    setFieldValue({
      key: `eEvaluationDashboard.evaluationDrawer.${key}`,
      value: value,
    }),
  )
}
