import React, { useEffect, useMemo, useState } from 'react'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import useLocalStorage from '@rehooks/local-storage'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import Box from '@mui/material/Box'
import JoditEditor from '../../../../components/Editor/JoditEditor'
import TextInput from '../../../../components/Input/TextInput'
import Autocomplete from '../../../../components/Input/Autocomplete'
import DatePicker from '../../../../components/Input/DatePicker'
import { styled } from '@mui/material/styles'
import dayjs from 'dayjs'
import {
  StyledCard,
  StyledColumnFlex,
  StyledColumnNoGap,
  StyledContainer,
  StyledRespRow,
  StyledRowFlex,
} from './Styled'
import {
  fetchOptions,
  handleAdminTel,
  handleAmountChange,
  handleCourseName,
  handleEditorChange,
  handleInputChange,
  handleSelectChange,
  handleNumberInput,
  handleRegister,
  handleTrainingDate,
  checkAssignNee,
  getOptionAdminDiffZone,
} from './events'
import DateCard from './DateCard'
import FooterContent from './Footer'
import TimePicker from '../../../../components/TimePicker/TimePicker'
import * as lib from '../../../../utils/lib'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(isSameOrAfter)

const Form = () => {
  const [user] = useLocalStorage('user')
  const dispatch = useDispatch()
  const { errors, setErrors } = useFormikContext()
  const {
    isCancel,
    isDisable,
    adminStaffOption,
    manageClassData,
    isLoading,
    isSubmit,
  } = useSelector(
    (state) => ({
      isCancel: state.manageClassForm.isCancel,
      isDisable: state.manageClassForm.isDisable,
      isLoading: state.manageClassForm.isLoading,
      adminStaffOption: state.manageClassForm.adminStaffOption,
      manageClassData: state.manageClassForm.data,
      isSubmit: state.manageClassForm.isSubmit,
    }),
    shallowEqual,
  )
  const { assignNee, monthlyPlan, startRegisterDateTime, endRegisterDateTime } =
    manageClassData
  const dates = _.get(monthlyPlan, 'dates', [])
  const workType = _.get(monthlyPlan, 'workType.workType', '')
  const isActivitySupport = workType === 'Activity Support'
  const area = _.get(monthlyPlan, 'area', undefined)
  const station = _.get(area, 'station', null)
  const courseName = handleCourseName(manageClassData)
  const nameForLearner = _.get(manageClassData, 'nameForLearner', '')
  const trainingDate = useMemo(
    () => handleTrainingDate(monthlyPlan),
    [monthlyPlan],
  )
  const tel = useMemo(
    () =>
      handleAdminTel(adminStaffOption, assignNee, manageClassData.assignNeeObj),
    [adminStaffOption, manageClassData],
  )
  const hasPermissionAdminAll = lib.validatePermission({
    user,
    moduleType: 'E_MANAGECLASS_SETTING',
    permission: ['ADMIN_ALL'],
  })
  const hasPermissionEditAll = lib.validatePermission({
    user,
    moduleType: 'E_MANAGECLASS_SETTING',
    permission: ['EDIT_ALL_CLASS'],
  })

  const [openRegisterDate, setOpenRegisterDate] = useState('')
  const [openRegisterTime, setOpenRegisterTime] = useState('')
  const [endRegisterDate, setEndRegisterDate] = useState('')
  const [endRegisterTime, setEndRegisterTime] = useState('')
  useMemo(
    () =>
      dispatch(
        handleRegister(
          'startRegisterDateTime',
          openRegisterDate,
          openRegisterTime,
        ),
      ),
    [openRegisterDate, openRegisterTime],
  )

  useMemo(
    () =>
      dispatch(
        handleRegister('endRegisterDateTime', endRegisterDate, endRegisterTime),
      ),
    [endRegisterDate, endRegisterTime],
  )

  useMemo(() => {
    if (isLoading && !isSubmit) {
      setOpenRegisterDate(
        !_.isEmpty(startRegisterDateTime)
          ? dayjs(startRegisterDateTime).format('YYYY-MM-DD')
          : '',
      )
      setOpenRegisterTime(
        !_.isEmpty(startRegisterDateTime)
          ? dayjs(startRegisterDateTime).format('HH:mm')
          : '10:00',
      )
      setEndRegisterDate(
        !_.isEmpty(endRegisterDateTime)
          ? dayjs(endRegisterDateTime).format('YYYY-MM-DD')
          : '',
      )

      setEndRegisterTime(
        !_.isEmpty(endRegisterDateTime)
          ? dayjs(endRegisterDateTime).format('HH:mm')
          : dayjs().endOf('day').format('HH:mm'),
      )
    }
  }, [isLoading])

  useEffect(() => {
    dispatch(fetchOptions(station, hasPermissionEditAll))
  }, [station])

  useEffect(() => {
    if (checkIsDateTimeValid(startRegisterDateTime, endRegisterDateTime)) {
      setErrors({ ...errors, endRegisterTime: 'error' })
      setEndRegisterTime(null)
    }
  }, [startRegisterDateTime, endRegisterDateTime])

  const checkIsDateTimeValid = (startRegisterDateTime, endRegisterDateTime) => {
    const format = 'DD/MM/YYYY HH:mm'
    const startDateTime = dayjs(startRegisterDateTime, format)
    const endDateTime = dayjs(endRegisterDateTime, format)
    return (
      startDateTime.isValid() &&
      endDateTime.isValid() &&
      startDateTime.isSameOrAfter(endDateTime)
    )
  }

  const disabledStartDate = (endRegisterDate) => {
    if (!_.isEmpty(endRegisterDate)) {
      const endRegisterDateFomat = dayjs(endRegisterDate)
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
      const startDateFormat = dayjs(_.get(monthlyPlan, 'startDate', '')).format(
        'YYYY-MM-DD',
      )
      if (endRegisterDateFomat < startDateFormat) {
        const endRegisterDateDefault =
          dayjs(endRegisterDate).format('YYYY-MM-DD')
        if (endRegisterDateDefault < startDateFormat) {
          return endRegisterDateDefault
        }
        return endRegisterDateFomat
      }
      return dayjs(startDateFormat).subtract(1, 'day')
    }
    return dayjs(_.get(monthlyPlan, 'startDate', ''))
      .subtract(1, 'day')
      .format('YYYY-MM-DD')
  }

  const optionAdminDiffZone = getOptionAdminDiffZone(
    manageClassData.selectedAssignNee,
    adminStaffOption,
  )

  const listValueAdmin = checkAssignNee(
    assignNee,
    [...adminStaffOption, optionAdminDiffZone],
    manageClassData.assignNeeObj,
  )

  const isEndRegisterTime = (error, Time) => {
    if (error === 'error') {
      return 'กรุณากรอกวันเวลาให้มากกว่าวันเวลาเปิดรับสมัคร'
    } else if (!_.isEmpty(error)) {
      return 'กรุณากรอกวันเวลาให้น้อยกว่าวันเวลาเริ่มอบรม'
    } else if (Time) {
      return 'กรุณากรอก'
    }
    return ''
  }

  const isRegister = (registerDate, error) => {
    if (!_.isEmpty(registerDate) && !_.isEmpty(error)) {
      return 'กรุณากรอกวันที่ให้ถูกต้อง'
    }
    return error
  }

  return (
    <StyledContainer>
      <StyledCard>
        <Typography variant="h6">รายละเอียด</Typography>
        <StyledRespRow>
          <LabelAndContent
            label="ประเภทตารางงาน"
            content={_.get(monthlyPlan, 'workType.workType', '-')}
          />
          <LabelAndContent
            label="หลักสูตร"
            content={courseName}
            subContent={
              <Typography variant="span" color="primary">
                {nameForLearner}
              </Typography>
            }
          />
        </StyledRespRow>
        <StyledRespRow>
          <LabelAndContent
            label="รูปแบบการสอน"
            content={_.get(
              manageClassData,
              'trainingPlatForm[0].trainingPlatform',
              '-',
            )}
          />
          <LabelAndContent label="วันที่จัดอบรม" content={trainingDate} />
        </StyledRespRow>
      </StyledCard>

      <StyledCard>
        <Typography variant="h6">วันเปิด/ปิดรับสมัคร</Typography>
        <StyledRowFlex>
          <Switch
            sx={{ mr: 0.5 }}
            inputProps={{
              'data-testid': 'switch-status',
            }}
            name="publicClass"
            checked={_.get(manageClassData, 'publicClass', false)}
            onChange={(e) => {
              if (!_.get(e, 'target.checked', false)) {
                setOpenRegisterDate('')
                setEndRegisterDate('')
              }
              dispatch(handleInputChange(e, true))
            }}
            disabled={isDisable || isCancel}
          />
          <Typography>Public Class</Typography>
        </StyledRowFlex>
        {_.get(manageClassData, 'publicClass', false) && (
          <StyledColumnNoGap>
            <StyledRespRow isInput>
              <BoxLabel>
                <DatePicker
                  id="startDate"
                  name="startDate"
                  labelText="วันเปิดรับสมัคร"
                  placeholder="เลือกวันที่"
                  required={!isActivitySupport}
                  value={openRegisterDate}
                  onChange={(value) => {
                    if (dayjs(value).isValid()) {
                      setOpenRegisterDate(dayjs(value).format('YYYY-MM-DD'))
                    } else {
                      setOpenRegisterDate(null)
                    }
                  }}
                  disabledInput={isDisable || isCancel}
                  disabledStartDate={''}
                  disabledEndDate={disabledStartDate(endRegisterDate)}
                  textError={isRegister(
                    openRegisterDate,
                    _.get(errors, 'startRegisterDateTime', ''),
                  )}
                />
              </BoxLabel>
              <BoxLabel>
                <Typography variant="body2" sx={{ display: 'flex' }}>
                  เวลาเปิด
                  {!isActivitySupport && (
                    <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                      *
                    </Typography>
                  )}
                </Typography>
                <TimePicker
                  id="startRegisterTime"
                  name="startRegisterTime"
                  value={openRegisterTime}
                  disabled={isDisable || isCancel}
                  disabledStartTime={openRegisterTime}
                  disabledEndTime={''}
                  onChange={(e) => setOpenRegisterTime(e.target.value)}
                  helperText={_.isEmpty(openRegisterTime) ? 'กรุณากรอก' : ''}
                />
              </BoxLabel>
            </StyledRespRow>

            <StyledRespRow isInput>
              <BoxLabel>
                <DatePicker
                  id="finishDate"
                  name="finishDate"
                  labelText="วันปิดรับสมัคร"
                  placeholder="เลือกวันที่"
                  required={!isActivitySupport}
                  disabled={_.isEmpty(openRegisterDate)}
                  disabledInput={_.isEmpty(openRegisterDate) || isDisable}
                  value={endRegisterDate}
                  onChange={(value) => {
                    if (dayjs(value).isValid()) {
                      setEndRegisterDate(dayjs(value).format('YYYY-MM-DD'))
                    } else {
                      setEndRegisterDate(null)
                    }
                  }}
                  disabledStartDate={openRegisterDate}
                  disabledEndDate={_.get(monthlyPlan, 'startDate', '')}
                  textError={isRegister(
                    endRegisterDate,
                    _.get(errors, 'endRegisterDateTime', ''),
                  )}
                />
              </BoxLabel>
              <BoxLabel>
                <Typography variant="body2" sx={{ display: 'flex' }}>
                  เวลาปิด
                  {!isActivitySupport && (
                    <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                      *
                    </Typography>
                  )}
                </Typography>
                <TimePicker
                  id="endRegisterTime"
                  name="endRegisterTime"
                  value={endRegisterTime === null ? '' : endRegisterTime}
                  disabledStartTime={''}
                  disabled={isDisable || isCancel}
                  disabledEndTime={''}
                  onChange={(e) => setEndRegisterTime(e.target.value)}
                  helperText={isEndRegisterTime(
                    _.get(errors, 'endRegisterTime', ''),
                    _.isEmpty(endRegisterTime),
                  )}
                />
              </BoxLabel>
            </StyledRespRow>
          </StyledColumnNoGap>
        )}
      </StyledCard>

      <StyledCard>
        <Typography variant="h6">รายละเอียดเพิ่มเติม</Typography>
        <JoditEditor
          id="detail"
          labelText="รายละเอียด"
          disabled={isDisable || isCancel}
          value={_.get(manageClassData, 'detail', '')}
          textError={_.get(errors, 'monthlyPlan.expectNumberPeople', '')}
          onBlur={(value) => dispatch(handleEditorChange(value))}
        />
      </StyledCard>

      <StyledCard>
        <Typography variant="h6">การจัดการคลาส</Typography>
        <StyledColumnNoGap noGap>
          <StyledRespRow isInput>
            <TextInput
              name="expectNumberPeople"
              type="basic"
              required={!isActivitySupport}
              labelText="จำนวนคนที่คาดหวัง"
              placeholder="คน"
              id="input-amount"
              inputProps={{
                type: 'number',
                onInput: handleNumberInput,
                onPaste: lib.handlePasteFormat,
              }}
              onWheel={(e) =>
                e.target instanceof HTMLElement && e.target.blur()
              }
              value={_.get(monthlyPlan, 'expectNumberPeople', '')}
              textError={_.get(errors, 'monthlyPlan.expectNumberPeople', '')}
              onChange={(e) => dispatch(handleAmountChange(e))}
              disabled={(hasPermissionAdminAll ? false : isDisable) || isCancel}
            />
            <Autocomplete
              name="assignNee"
              labelText="แอดมินหลัก"
              disabled={isDisable || isCancel}
              required={!isActivitySupport}
              options={[...adminStaffOption]}
              defaultValue={listValueAdmin}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  placeholder={'กรุณาเลือก'}
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    'data-testid': 'select-main-admin',
                  }}
                />
              )}
              handleChange={(e, element) => {
                const id = e.target.id || ''
                const assignNeeUuid = element ? id.replace(/^\d+-/, '') : ''
                dispatch(
                  handleSelectChange({
                    dates,
                    keyField: 'assignNee',
                    value: assignNeeUuid,
                  }),
                )
              }}
              handleInputChange={(e) => {
                if (_.isNull(e)) {
                  dispatch(
                    handleSelectChange({
                      keyField: 'assignNeeObj',
                      value: null,
                    }),
                  )
                }
              }}
              textError={_.get(errors, 'assignNee', '')}
            />
          </StyledRespRow>
          <StyledRespRow>
            <LabelAndContent
              label="รูปแบบการจัดห้องเรียน"
              content={_.get(monthlyPlan, 'classroom.classroom', '-')}
            />
            <LabelAndContent label="เบอร์โทรติดต่อ Admin หลัก" content={tel} />
          </StyledRespRow>
        </StyledColumnNoGap>
        <StyledRespRow>
          <LabelAndContent
            label="Department"
            content={_.get(area, 'department.department', '-')}
          />
          <LabelAndContent
            label="Station"
            content={_.get(area, 'station.station', '-')}
          />
        </StyledRespRow>
        <StyledRespRow>
          <LabelAndContent
            label="การเตรียมอาหาร Break / Lunch"
            content={monthlyPlan.break ? 'ต้องการ' : 'ไม่ต้องการ'}
          />
          <LabelAndContent
            label="ค่าใช้จ่ายต่อหัว"
            content={`${_.get(monthlyPlan, 'cost', '0')}.00 บาท`}
          />
        </StyledRespRow>
      </StyledCard>

      {dates.map((item, index) => (
        <DateCard
          data={item}
          index={index}
          key={index}
          isActivitySupport={isActivitySupport}
          listValueAdmin={listValueAdmin}
        />
      ))}

      <StyledCard>
        <Typography variant="h6">ช่องทางติดต่อ</Typography>
        <TextInput
          type="basic"
          multiline
          rows={2}
          maxlength={3000}
          isShowTextError={false}
          name="contact"
          id="contact"
          defaultValue={_.get(manageClassData, 'contact', '')}
          onBlur={(e) => dispatch(handleInputChange(e))}
          disabled={isDisable || isCancel}
        />
      </StyledCard>

      <FooterContent data={manageClassData} />
    </StyledContainer>
  )
}

export default Form

export const LabelAndContent = ({ label, content, subContent }) => {
  const width = 'calc(50% - 12px)'
  return (
    <StyledColumnFlex sx={{ gap: 0, width, minWidth: width }}>
      <Typography variant="body2" color="text.secondary">
        {label}
      </Typography>
      <Typography sx={{ wordBreak: 'break-word' }}>
        {content} {subContent}
      </Typography>
    </StyledColumnFlex>
  )
}

const BoxLabel = styled(Box)(() => ({
  width: '100%',
}))
