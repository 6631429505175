import React from 'react'
import { useSelector } from 'react-redux'
import { BoxSearch } from './Styled'
import DateRangeInput from '../../../../components/DateRangeInput'

const SearchCustom = () => {
  const { search, handleDateSearch } = useSelector((state) => state.table2)

  return (
    <BoxSearch>
      <DateRangeInput
        dateState={{
          startDate: search?.searchDateRange?.startDate || new Date(),
          endDate: search?.searchDateRange?.endDate || new Date(),
          display: search?.searchDateRange?.displayDate || false,
          key: 'selection',
        }}
        onChange={(selectedDates) => {
          handleDateSearch(selectedDates)
        }}
        placeholder={'ค้นหาวันที่สอบ'}
        handleClear={() => {
          handleDateSearch(null)
          document.body.click()
        }}
        isClearable={true}
        clearButtonId={'clear-date-range-button'}
      />
    </BoxSearch>
  )
}

export default SearchCustom
