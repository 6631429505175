import React from 'react'

import RadioButtonCheckedOutlined from '@mui/icons-material/RadioButtonCheckedOutlined'
import CheckBoxRounded from '@mui/icons-material/CheckBoxRounded'
import ShortTextRounded from '@mui/icons-material/ShortTextRounded'
import StarHalf from '@mui/icons-material/StarHalf'

import { themeColor } from './../utils/themeData'
import { path } from './path'

export const E_EVALUATION_SETTING = 'E_EVALUATION_SETTING'

export const headCells = [
  {
    id: 'status',
    label: '',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'codeId',
    disablePadding: false,
    label: 'ID',
    width: '80px',
  },
  {
    id: 'name',
    label: 'ชื่อแบบประเมิน',
    disablePadding: false,
  },
  {
    id: 'eEvaluationType',
    label: 'ประเภท',
    disablePadding: false,
  },
  {
    id: 'numberOfVersions',
    label: 'จำนวนเวอร์ชัน',
    disablePadding: false,
  },
  {
    id: 'updatedBy',
    label: 'แก้ไขล่าสุดโดย',
    disablePadding: false,
  },
  {
    id: 'actionE',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideEdit: true,
    hideDelete: true,
    hideSortIcon: true,
    viewPath: path.eEvaluation,
  },
]

export const versionHeadCells = [
  {
    id: 'status',
    label: '',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'version',
    disablePadding: false,
    label: 'เวอร์ชัน',
    width: '80px',
  },
  {
    id: 'name',
    label: 'ชื่อแบบประเมิน',
    disablePadding: false,
  },
  {
    id: 'startDate',
    label: 'วันเริ่มต้นใช้งาน',
    disablePadding: false,
  },
  {
    id: 'updatedBy',
    label: 'แก้ไขล่าสุดโดย',
    disablePadding: false,
  },
  {
    id: 'versionStatus',
    label: 'สถานะ',
    disablePadding: false,
  },
  {
    id: 'actionEVersion',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideDelete: true,
    hideSortIcon: true,
    viewPath: `${path.eEvaluation}/version/view`,
    editPath: `${path.eEvaluation}/version/edit`,
    module: 'E_EVALUATION_SETTING', // 'E_EVALUATION_SETTING'
  },
]

export const defaultValue = {
  defaultTap: {
    assessment: 'assessment',
    setting: 'setting',
    feedback: 'feedback',
  },
  eEvaluationType: {
    class: 'CLASS',
    standalone: 'STANDALONE',
  },
  position: {
    left: 'LEFT',
    rihht: 'CENTER',
    center: 'RIGHT',
  },
  questionType: {
    single: 'SINGLE',
    multiple: 'MULTIPLE',
    freeText: 'FREE_TEXT',
    rating: 'RATING',
    ranking: 'RAKING',
  },
  mediaType: {
    image: 'VIDEO',
    video: 'IMAGE',
  },
  //setting
  displayQuestion: {
    all: 'ALL',
    one: 'ONE',
  },
  //preview
  defaultSection: {
    evaluate: 'EVALUATE',
    result: 'RESULT',
  },
}

export const listPositionEva = [
  {
    label: 'Left align',
    value: 'LEFT',
  },
  {
    label: 'Center align',
    value: 'CENTER',
  },
  {
    label: 'Right align',
    value: 'RIGHT',
  },
]

export const listChoiceEva = [
  {
    label: 'Single Choice',
    value: 'SINGLE',
  },
  {
    label: 'Multiple Choice',
    value: 'MULTIPLE',
  },
  {
    label: 'Rating',
    value: 'RATING',
  },
  {
    label: 'Free Text',
    value: 'FREE_TEXT',
  },
  // {
  //   label: 'Ranking',
  //   value: 'RANKING',
  // },
]

export const optionQuestionEva = [
  {
    labelText: 'Single Choice',
    icon: <RadioButtonCheckedOutlined color="action" />,
    value: 'SINGLE',
  },
  {
    labelText: 'Multiple Choice',
    icon: <CheckBoxRounded color="action" />,
    value: 'MULTIPLE',
  },
  {
    labelText: 'Rating',
    icon: <StarHalf color="action" />,
    value: 'RATING',
  },
  {
    labelText: 'Free Text',
    icon: <ShortTextRounded color="action" />,
    value: 'FREE_TEXT',
  },
  // {
  //   labelText: 'Ranking',
  //   icon: <FormatListNumbered color="action" />,
  //   value: 'RANKING',
  // },
]

export const optionSwap = [
  {
    label: 'ก่อน',
    value: 'BEFORE',
  },
  {
    label: 'หลัง',
    value: 'AFTER',
  },
]

export const sectionName = {
  courses: 'แบบประเมินเนื้อหาหลักสูตร',
  lecturers: 'แบบประเมินวิทยากร',
  courseManagements: 'แบบประเมินการบริหารจัดการหลักสูตร',
  question: 'แบบประเมิน/แบบสอบถามอื่นๆ',
}

export const section = {
  COURSE_ASSESSMENTS: 'COURSE_ASSESSMENTS',
  LECTURER_ASSESSMENTS: 'LECTURER_ASSESSMENTS',
  COURSE_MANAGEMENT_ASSESSMENTS: 'COURSE_MANAGEMENT_ASSESSMENTS',
  QUESTIONS: 'QUESTIONS',
}

export const ratingColor = [
  themeColor.grey.grey50,
  themeColor.rating.theBest,
  themeColor.rating.great,
  themeColor.rating.veryGood,
  themeColor.rating.good,
  themeColor.rating.ok,
  themeColor.rating.notBad,
  themeColor.rating.notOk,
  themeColor.rating.bad,
  themeColor.rating.veryBad,
  themeColor.rating.worst,
]

export const ratingChoices = [
  { title: 'ดีมาก', sequence: 1 },
  { title: 'ดี', sequence: 2 },
  { title: 'ปานกลาง', sequence: 3 },
  { title: 'พอใช้', sequence: 4 },
  { title: 'ควรปรับปรุง', sequence: 5 },
]

export const listTypeOfAnswers = [
  {
    label: 'อย่างน้อย',
    value: 'MINIMUM',
  },
  {
    label: 'มากสุด',
    value: 'MAXIMUM',
  },
  {
    label: 'เท่ากับ',
    value: 'EQUAL',
  },
]

export const answerWording = {
  NONE: 'ตอบได้หลายข้อ',
  MINIMUM: 'ตอบอย่างน้อย',
  MAXIMUM: 'ตอบได้มากสุด',
  EQUAL: 'เลือกตอบ',
}

export const assessmentField = {
  course: 'courses',
  lecturer: 'lecturers',
  courseManagement: 'courseManagements',
  question: 'questions',
}

export const mockLecturerName = {
  men: 'สมชาย ใจดี',
  women: 'สมหญิง ใจดี',
}

export const errorMessageField = {
  DATE: 'DATE',
  NAME: 'NAME',
  TITLE1: 'TITLE1',
  TITLE2: 'TITLE2',
  DESCRIPTION: 'DESCRIPTION',
  COVER_TEXT: 'COVER_TEXT',
  ASSESSMENT: 'ASSESSMENT',
  MANAGE_ASSESSMENT: 'MANAGE_ASSESSMENT',
  LECTURER_ASSESSMENT: 'LECTURER_ASSESSMENT',
  CHOICES: 'CHOICES',
  ANSWER: 'ANSWER',
  QUESTION: 'QUESTION',
  QUESTION_QUESTION: 'QUESTION_QUESTION',
}

export const errorMessageType = {
  OUT_LENGTH_3000: 'กรุณากรอกไม่เกิน 3000 ตัวอักษร',
  OUT_LENGTH_100: 'กรุณากรอกไม่เกิน 100 ตัวอักษร',
  OUT_LENGTH_255: 'กรุณากรอกไม่เกิน 255 ตัวอักษร',
  EMPTY_QUESTION: 'กรุณากรอกคำถาม',
  EMPTY_ANSWER: 'กรุณากรอกตัวเลืิอก',
  EMPTY_NAME: 'กรุณากรอกชื่อแบบประเมิน',
  EMPTY_DATE: 'กรุณาระบุวันเริ่มต้นใช้งาน',
  EMPTY: 'กรุณาระบุ',
}
