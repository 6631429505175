import callAxios from '../../../../../../utils/baseService'
import _ from 'lodash'
import { store } from '../../../../../../App'
import { setFieldValue } from '../../../../../../redux/slices/crud'
import { overviewEnum } from '../enums/drawerEnum'
import {
  eEvaluationDashboardDetail,
  eEvaluationDashboard as eEvaluationDashboardApi,
} from '../../../../../../utils/apiPath'
import dayjs from 'dayjs'

export const fetchOverview = async (
  uuid = null,
  isManageClass = false,
  startDate = '',
  finishDate = '',
  trainingPlanUuid = '',
) => {
  const { eEvaluationDashboard } = store.getState().crud
  const eEvaluationDetail = store.getState().eEvaluationDetail
  const eEvaluationVersion = uuid ? uuid : eEvaluationDetail?.result?.uuid || ''
  const body = {
    eEvaluationVersion: eEvaluationVersion,
    startDate: isManageClass
      ? ''
      : eEvaluationDashboard?.filtersDrawer?.isFilterUserResponseDate
      ? eEvaluationDashboard?.filtersDrawer?.dateFilterStartDate ?? ''
      : eEvaluationDashboard?.filtersDrawer?.dateFilterStartDate ??
        dayjs().startOf('month').format('YYYY-MM-DD'),
    endDate: isManageClass
      ? ''
      : eEvaluationDashboard?.filtersDrawer?.isFilterUserResponseDate
      ? eEvaluationDashboard?.filtersDrawer?.dateFilterFinishDate ?? ''
      : eEvaluationDashboard?.filtersDrawer?.dateFilterFinishDate ??
        dayjs().startOf('month').format('YYYY-MM-DD'),
    userResponseDateStart: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.userResponseDateStart,
      dayjs().format('YYYY-MM-DD'),
    ),
    userResponseDateEnd: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.userResponseDateEnd,
      dayjs().format('YYYY-MM-DD'),
    ),
    courseName: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.courseName,
      '',
    ),
    trainingDateStartDate: isManageClass
      ? startDate
      : _.defaultTo(
          eEvaluationDashboard?.filtersDrawer?.trainingDateStartDate,
          '',
        ),
    trainingDateFinishDate: isManageClass
      ? finishDate
      : _.defaultTo(
          eEvaluationDashboard?.filtersDrawer?.trainingDateFinishDate,
          '',
        ),
    trainerName: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.trainerName,
      '',
    ),
    distribution: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.distribution,
      '',
    ),
    trainingPlanUuid: isManageClass ? trainingPlanUuid : '',
    courseCode: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.courseCode,
      '',
    ),
    department: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.department,
      '',
    ),
    station: _.defaultTo(eEvaluationDashboard?.filtersDrawer?.station, ''),
  }
  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: true,
    }),
  )
  const url = isManageClass
    ? `${eEvaluationDashboardApi}/manage-class`
    : eEvaluationDashboardDetail
  await callAxios
    .post(url, body)
    .then(({ data }) => {
      store.dispatch(
        setFieldValue({
          key: 'eEvaluationDashboard.latestUpdated',
          value: _.get(data, 'result.latestUpdated', undefined),
        }),
      )

      const result = data.result
      const answerPart1 = _.defaultTo(result.courseEva?.answers, [])
      const answers1 = prepareAnswer(answerPart1)
      const answerPart3 = _.defaultTo(result.courseManageEva?.answers, [])
      const answers3 = prepareAnswer(answerPart3)

      store.dispatch(
        setFieldValue({
          key: 'eEvaluationDashboard.overview',
          value: {
            ...overviewEnum,
            ...result,
            courseEva: {
              attendeeCount: result.courseEva.attendeeCount,
              respondentCount: result.courseEva.respondentCount,
              overallPercentage: Number(result.courseEva.overallPercentage),
              answers: answers1,
              drawerData: result.courseEva.drawerData ?? [],
            },
            courseManageEva: {
              attendeeCount: result.courseManageEva.attendeeCount,
              respondentCount: result.courseManageEva.respondentCount,
              overallPercentage: Number(
                result.courseManageEva.overallPercentage,
              ),
              answers: answers3,
              drawerData: result.courseManageEva.drawerData ?? [],
            },
            trainerEva: result.trainerEva ?? [],
            part4Eva: result.part4Eva ?? [],
          },
        }),
      )
      store.dispatch(
        setFieldValue({
          key: 'eEvaluationDashboard.filtersDrawer.isManageClass',
          value: isManageClass,
        }),
      )
      if (isManageClass) {
        store.dispatch(
          setFieldValue({
            key: 'eEvaluationDashboard.filtersDrawer.courseName',
            value: result?.courseName || '',
          }),
        )
      }
    })
    .catch((err) => {
      console.log(err)
    })

  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: false,
    }),
  )
}

const prepareAnswer = (answers) => {
  return Object.values(
    answers.reduce((acc, item) => {
      if (!acc[item.title]) {
        acc[item.title] = { title: item.title, count: 0, value: 0 }
      }
      acc[item.title].count += item.count
      return acc
    }, {}),
  )
}
